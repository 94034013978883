import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  DATA_SUBMIT_ACTION,
  IMPORT_BULK_USER_REQUEST,
  getSearch,
  MOVE_STAGING_BULK_USER_REQUEST,
} from "../actions";
import {
  dataSubmitSuccess,
  dataSubmitError,
  importBulkUserSuccess,
  importBulkUserError,
  moveStagingBulkUserSuccess,
  moveStagingBulkUserError,
} from "./action";
import { api, DataEntryAPI } from "../../config/api";
import { getApiHeader } from "../../helpers/utils";
import { toast } from "react-toastify";

function* dataEntryCall(payload) {
  try {
    const data = yield call(dataEntryCallAsync, payload);
    yield put(getSearch());
    yield put(dataSubmitSuccess(data));
  } catch (error) {
    yield put(dataSubmitError(error));
  }
}

function* bulkUserCall(payload) {
  try {
    const data = yield call(bulkUserCallAsync, payload);
    yield put(importBulkUserSuccess(data));
  } catch (error) {
    yield put(importBulkUserError(error));
  }
}
function* moveStagingBulkUserCall(payload) {
  try {
    const data = yield call(moveStagingBulkUserAsync, payload);
    yield put(moveStagingBulkUserSuccess(data));
  } catch (error) {
    yield put(moveStagingBulkUserError(error));
  }
}
// axios({
//     method: 'post',
//     url: `${ DataEntryAPI }/UpdateRosterAttributeInfo`,
//     headers: getApiHeader(),
//     data: JSON.stringify(payload)
// }).then(function (res)
const dataEntryCallAsync = ({ payload }) => {
  axios({
    method: "post",
    url: `${DataEntryAPI}/InsertRosterUser`,
    headers: getApiHeader(),
    data: JSON.stringify(payload),
  })
    .then((res) => {
      toast.success("User(s) added successfully.");
      return res;
    })
    .catch((err) => {
      // toast.error(err)
      return toast.error("Something is wrong.");
    });
};
const bulkUserCallAsync = ({ payload }) => {
  return axios({
    method: "post",
    url: `${DataEntryAPI}/ImportBulkUser`,
    headers: getApiHeader(),
    data: payload,
  })
    .then((res) => {
      toast.success("User(s) imported successfully.");
      return res.data;
    })
    .catch((err) => {
      toast.error(err + "Something is wrong.");
      throw err;
    });
};
const moveStagingBulkUserAsync = ({ payload }) => {
  return axios({
    method: "post",
    url: `${DataEntryAPI}/MoveStagingBulkUser`,
    headers: getApiHeader(),
    data: payload,
  })
    .then((res) => {
      toast.success("User(s) imported successfully.");
      return res.data;
    })
    .catch((err) => {
      toast.error(err + "Something is wrong.");
      throw err;
    });
};
export function* watchDataEntry() {
  yield takeEvery(DATA_SUBMIT_ACTION, dataEntryCall);
}
export function* importBulkUser() {
  yield takeEvery(IMPORT_BULK_USER_REQUEST, bulkUserCall);
}
export function* moveStagingBulkUser() {
  yield takeEvery(MOVE_STAGING_BULK_USER_REQUEST, moveStagingBulkUserCall);
}
export default function* rootSaga() {
  yield all([
    fork(watchDataEntry),
    fork(importBulkUser),
    fork(moveStagingBulkUser),
  ]);
}
