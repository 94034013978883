import axios from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
    ADD_NEW_SESSION,
    GET_NEW_SESSION_ACTION,
    GET_TOP_COURSE,
    GET_STATISTIC_TAB_COUNT,
    DELETE_SESSION_ACTION,
    GET_SESSION_BY_ID_ACTION,
    GET_COURSE_DROPDOWN,
    ADD_STUDENT_TRAINER,
    DELETE_STUDENT_TRAINER,
    ADD_LOCATION
} from '../actions';

import {
    addNewSessionSuccess,
    addNewSessionError,
    getNewSessionSuccess,
    getNewSessionError,
    getTopCourseSuccess,
    getTopCourseError,
    getStatisticTabCountSuccess,
    getStatisticTabCountError,
    deleteSessionSuccess,
    deleteSessionError,
    getSessionByIdSuccess,
    getSessionByIdError,
    getCourseDropdownSuccess,
    getCourseDropdownError,
    addStudentTrainerSuccess,
    addStudentTrainerError,
    getSessionByIdAction,
    deleteStudentTrainerSuccess,
    deleteStudentTrainerError,
    addLocatoinSuccess,
    addLocatoinError,

} from './actions';

import {
    getNewSessionAction
} from '../actions'

import { getApiHeader, getCompanyGUID } from '../../helpers/utils'
import { DataEntryAPI } from '../../config/api';


export function* watchNewSessionEntry() {
    yield takeEvery(ADD_NEW_SESSION, newSessionEntryCall);
}
export function* watchGetSession() {
    yield takeEvery(GET_NEW_SESSION_ACTION, getSessionCall);
}
export function* watchTopCourse() {
    yield takeEvery(GET_TOP_COURSE, topCourseCall);
}
export function* watchStatisticTabCount() {
    yield takeEvery(GET_STATISTIC_TAB_COUNT, statisticTabCountCall);
}
export function* watchDeleteSession() {
    yield takeEvery(DELETE_SESSION_ACTION, deleteSessionCall);
}
export function* watchSessionById() {
    yield takeEvery(GET_SESSION_BY_ID_ACTION, sessionByIdCall);
}
export function* watchCourseDropdown() {
    yield takeEvery(GET_COURSE_DROPDOWN, courseDropdownCall);
}
export function* watchAddStudentTrainer() {
    yield takeEvery(ADD_STUDENT_TRAINER, addStudentTrainerCall);
}
export function* watchDeleteStudentTrainer() {
    yield takeEvery(DELETE_STUDENT_TRAINER, deleteStudentTrainerCall);
}
export function* watchAddLocation() {
    yield takeEvery(ADD_LOCATION, addLocationCall);
}



function* newSessionEntryCall({ payload }) {
    try {
        const data = yield call(newSessionEntryCallAsync, payload);
        yield put(getNewSessionAction())
        yield put(addNewSessionSuccess(data));
    } catch (error) {
        yield put(addNewSessionError(error));
    }
}
function* getSessionCall() {
    try {
        const data = yield call(getSessionCallAsync);
        yield put(getNewSessionSuccess(data));
    } catch (error) {
        yield put(getNewSessionError(error));
    }
}
function* topCourseCall() {
    try {
        const data = yield call(topCourseCallAsync);
        yield put(getTopCourseSuccess(data));
    } catch (error) {
        yield put(getTopCourseError(error));
    }
}
function* statisticTabCountCall() {
    try {
        const data = yield call(statisticTabCountCallAsync);
        yield put(getStatisticTabCountSuccess(data));
    } catch (error) {
        yield put(getStatisticTabCountError(error));
    }
}
function* deleteSessionCall({ payload }) {
    try {
        const data = yield call(deleteSessionCallAsync, payload);
        yield put(getNewSessionAction())
        yield put(deleteSessionSuccess(data));
    } catch (error) {
        yield put(deleteSessionError(error));
    }
}
function* sessionByIdCall({ payload }) {
    try {
        const data = yield call(sessionByIdCallAsync, payload);
        yield put(getSessionByIdSuccess(data));
    } catch (error) {
        yield put(getSessionByIdError(error));
    }
}
function* courseDropdownCall() {
    try {
        const data = yield call(courseDropdownCallAsync);
        yield put(getCourseDropdownSuccess(data));
    } catch (error) {
        yield put(getCourseDropdownError(error));
    }
}
function* addStudentTrainerCall({ payload }) {
    try {
        const data = yield call(addStudentTrainerCallAsync, payload);
        yield put(getSessionByIdAction(payload?.sessionId))
        yield put(getNewSessionAction())
        yield put(addStudentTrainerSuccess(data));
    } catch (error) {
        yield put(addStudentTrainerError(error));
    }
}
function* deleteStudentTrainerCall({ payload }) {
    try {
        const data = yield call(deleteStudentTrainerCallAsync, payload);
        yield put(getSessionByIdAction(payload?.sessionId))
        yield put(getNewSessionAction());
        yield put(deleteStudentTrainerSuccess(data))
    } catch (error) {
        yield put(deleteStudentTrainerError(error));
    }
}
function* addLocationCall({ payload }) {
    try {
        const data = yield call(addLocationCallAsync, payload);
        // yield put(getNewSessionAction());
        yield put(addLocatoinSuccess(data))
    } catch (error) {
        yield put(addLocatoinError(error));
    }
}

const newSessionEntryCallAsync = (payload) => {
    return axios({
        method: 'POST',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/CreateTrainingCource?companyGuid=${getCompanyGUID()}&courceId=${payload.courceId}&courceName=${payload.courceName}&StartDate=${payload.StartDate}&EndDate=${payload.EndDate}`,
        data: payload
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}

const getSessionCallAsync = () => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetCreatedTrainingSessions?companyGuid=${getCompanyGUID()}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const topCourseCallAsync = () => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetCreatedTopTrainingSessions?companyguid=${getCompanyGUID()}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const statisticTabCountCallAsync = () => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetTrainingSessionWidgets?companyguid=${getCompanyGUID()}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const deleteSessionCallAsync = (payload) => {
    return axios({
        method: 'DELETE',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/DeleteTrainingSession?companyguid=${getCompanyGUID()}&TrainingSessionId=${payload.trainingSessionId}&SessionId=${payload.sessionId}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const sessionByIdCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetCourceTrainingSessionBySessionId?SessionId=${payload}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const courseDropdownCallAsync = () => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetCourceList?companyGuid=${getCompanyGUID()}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}

const addStudentTrainerCallAsync = (payload) => {
    payload.companyguid = getCompanyGUID();
    return axios({
        method: 'POST',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/InsertTrainingSessionPerson`,
        data: payload
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}

const deleteStudentTrainerCallAsync = (payload) => {
    return axios({
        method: 'DELETE',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/deleteAssignesSessionid?assignedId=${payload.assignedId}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
const addLocationCallAsync = (payload) => {
    return axios({
        method: 'POST',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/UpdateCourseLocations?companyguid=${getCompanyGUID()}&sessionId=${payload.sessionId}&LocationName=${payload.LocationName}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}



export default function* rootSaga() {
    yield all([
        fork(watchNewSessionEntry),
        fork(watchGetSession),
        fork(watchTopCourse),
        fork(watchStatisticTabCount),
        fork(watchDeleteSession),
        fork(watchSessionById),
        fork(watchCourseDropdown),
        fork(watchAddStudentTrainer),
        fork(watchDeleteStudentTrainer),
        fork(watchAddLocation),
    ]);
}