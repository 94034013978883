import {
    ADD_STUDENT_TRAINER,
    ADD_STUDENT_TRAINER_SUCCESS,
    ADD_STUDENT_TRAINER_ERROR,
    ADD_NEW_SESSION,
    ADD_NEW_SESSION_ERROR,
    ADD_NEW_SESSION_SUCCESS,
    GET_NEW_SESSION_ACTION,
    GET_NEW_SESSION_ERROR,
    GET_NEW_SESSION_SUCCESS,
    DELETE_SESSION_ACTION,
    DELETE_SESSION_SUCCESS,
    DELETE_SESSION_ERROR,
    GET_TOP_COURSE,
    GET_TOP_COURSE_SUCCESS,
    GET_TOP_COURSE_ERROR,
    GET_STATISTIC_TAB_COUNT,
    GET_STATISTIC_TAB_COUNT_SUCCESS,
    GET_STATISTIC_TAB_COUNT_ERROR,
    DELETE_STUDENT_TRAINER,
    DELETE_STUDENT_TRAINER_ERROR,
    DELETE_STUDENT_TRAINER_SUCCESS,
    GET_SESSION_BY_ID_ACTION,
    GET_SESSION_BY_ID_SUCCESS,
    GET_SESSION_BY_ID_ERROR,
    GET_COURSE_DROPDOWN,
    GET_COURSE_DROPDOWN_SUCCESS,
    GET_COURSE_DROPDOWN_ERROR,    
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_ERROR,
} from '../actions';

const INIT_STATE = {
    newSessionList: [],
    newSessionLoading: false,
    newSessionError: '',

    addSessionDetail: [],
    addSessionLoading: false,
    addSessionError: '',

    deleteDetail: [],
    deleteSessionLoading: false,
    deleteSessionError: '',

    studentTrainerDetail: [],
    studentTrainerLoading: false,
    studentTrainerError: '',

    topCourseList: [],
    topCourseListLoading: false,
    topCourseListError: '',

    statisticCount: [],
    statisticCountLoading: false,
    statisticCountError: '',

    deleteStudentTrainerDetail: [],
    deleteStudentTrainerLoading: false,
    deleteStudentTrainerError: '',

    sessionByIdDetail: {},
    sessionByIdLoading: false,
    sessionByIdError: '',

    courseDropdown: null,
    courseDropdownLoading: false,
    courseDropdownError: '',

    locationLoading: false,
    locationError: '',

}

const trainingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_NEW_SESSION_ACTION:
            return { ...state, newSessionLoading: true, newSessionError: '' };
        case GET_NEW_SESSION_SUCCESS:
            return { ...state, newSessionList: action.payload, newSessionError: '', newSessionLoading: false };
        case GET_NEW_SESSION_ERROR:
            return { ...state, newSessionError: action.payload.message, newSessionLoading: false };

        case ADD_NEW_SESSION:
            return { ...state, addSessionLoading: true, addSessionError: '' };
        case ADD_NEW_SESSION_SUCCESS:
            return { ...state, addSessionDetail: action.payload, addSessionError: '', addSessionLoading: false };
        case ADD_NEW_SESSION_ERROR:
            return { ...state, addSessionError: action.payload.message, addSessionLoading: false };

        case DELETE_SESSION_ACTION:
            return { ...state, deleteSessionLoading: true, addSessionError: '' };
        case DELETE_SESSION_SUCCESS:
            return { ...state, addSessionDetail: action.payload, addSessionError: '', deleteSessionLoading: false };
        case DELETE_SESSION_ERROR:
            return { ...state, addSessionError: action.payload.message, deleteSessionLoading: false };

        case ADD_STUDENT_TRAINER:
            return { ...state, deleteStudentTrainerLoading: true, deleteStudentTrainerError: '' };
        case ADD_STUDENT_TRAINER_SUCCESS:
            return { ...state, deleteStudentTrainerDetail: action.payload, deleteStudentTrainerError: '', deleteStudentTrainerLoading: false };
        case ADD_STUDENT_TRAINER_ERROR:
            return { ...state, deleteStudentTrainerError: action.payload.message, deleteStudentTrainerLoading: false };

        case GET_TOP_COURSE:
            return { ...state, topCourseListLoading: true, topCourseListError: '' };
        case GET_TOP_COURSE_SUCCESS:
            return { ...state, topCourseList: action.payload, topCourseListError: '', topCourseListLoading: false };
        case GET_TOP_COURSE_ERROR:
            return { ...state, topCourseListError: action.payload.message, topCourseListLoading: false };

        case GET_STATISTIC_TAB_COUNT:
            return { ...state, statisticCountLoading: true, statisticCountError: '' };
        case GET_STATISTIC_TAB_COUNT_SUCCESS:
            return { ...state, statisticCount: action.payload, statisticCountError: '', statisticCountLoading: false };
        case GET_STATISTIC_TAB_COUNT_ERROR:
            return { ...state, statisticCountError: action.payload.message, statisticCountLoading: false };


        case DELETE_STUDENT_TRAINER:
            return { ...state, deleteleaderLoading: true, deleteleaderError: '' };
        case DELETE_STUDENT_TRAINER_SUCCESS:
            return { ...state, deleteLeaderDetail: action.payload, deleteleaderError: '', leaderLoading: false };
        case DELETE_STUDENT_TRAINER_ERROR:
            return { ...state, deleteleaderError: action.payload.message, deleteleaderLoading: false };

        case GET_SESSION_BY_ID_ACTION:
            return { ...state, sessionByIdLoading: true, sessionByIdError: '', sessionByIdDetail: {} };
        case GET_SESSION_BY_ID_SUCCESS:
            return { ...state, sessionByIdDetail: action.payload, sessionByIdError: '', sessionByIdLoading: false };
        case GET_SESSION_BY_ID_ERROR:
            return { ...state, sessionByIdError: action.payload.message, sessionByIdLoading: false };

        case GET_COURSE_DROPDOWN:
            return { ...state, courseDropdownLoading: true, courseDropdownError: '' };
        case GET_COURSE_DROPDOWN_SUCCESS:
            return { ...state, courseDropdown: action.payload, courseDropdownError: '', courseDropdownLoading: false };
        case GET_COURSE_DROPDOWN_ERROR:
            return { ...state, courseDropdownError: action.payload.message, courseDropdownLoading: false, courseDropdown:[] };
       
        case ADD_LOCATION:
            return { ...state, locationLoading: true, locationError: '' };
        case ADD_LOCATION_SUCCESS:
            return { ...state, locationLoading: false, locationError: ''};
        case ADD_LOCATION_ERROR:
            return { ...state, locationError: action.payload.message, locationLoading: false};

        default: return { ...state };
    }
}
export default trainingReducer