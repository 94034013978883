import {
    GET_SEARCH,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,
} from '../actions';

const INIT_STATE = {
    searchList: null,
    searchListLoading: false,
    searchListError: ''
}

const topbarReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SEARCH:
            return { ...state, searchList: null, searchListLoading: true, searchListError: '' };
        case GET_SEARCH_SUCCESS:
            return { ...state, searchList: action.payload, searchListLoading: false, searchListError: '' };
        case GET_SEARCH_ERROR:
            return { ...state, searchListLoading: false, searchListError: action.payload };
        default: return { ...state };
    }
}
export default topbarReducer