import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import { USERPERMISSION_SEARCH_DROPDOWN_LIST } from "../actions";

import {
  userPermissionSearchDropdownSuccess,
  userPermissionSearchDropdownError,
} from "./action";

import { PermissionSearchData } from "../../data/PermissionSearch";

export function* watchUserPermissionDropdown() {
  yield takeEvery(
    USERPERMISSION_SEARCH_DROPDOWN_LIST,
    userPermissionSearchCall
  );
}

function* userPermissionSearchCall({ payload }) {
  try {
    const data = yield call(userPermissionSearchDropdownCallAsync, payload);
    yield put(userPermissionSearchDropdownSuccess(data));
  } catch (error) {
    yield put(userPermissionSearchDropdownError(error));
  }
}

const userPermissionSearchDropdownCallAsync = () => {
  return PermissionSearchData;
};

export default function* rootSaga() {
  yield all([fork(watchUserPermissionDropdown)]);
}
