/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const SSO_USER_LOGIN = "SSO_USER_LOGIN";
export const SET_ADMIN = "SET_ADMIN";

// dataentry
export const DATA_SUBMIT_ACTION = "DATA_SUBMIT_ACTION";
export const DATA_SUBMIT_SUCCESS = "DATA_SUBMIT_SUCCESS";
export const DATA_SUBMIT_ERROR = "DATA_SUBMIT_ERROR";

//editprofile
export const PROFILE_PICTURE_LOADER = "PROFILE_PICTURE_LOADER";
export const GET_BASIC_INFO_ACTION = "GET_BASIC_INFO_ACTION";
export const GET_BASIC_INFO_SUCCESS = "GET_BASIC_INFO_SUCCESS";
export const GET_BASIC_INFO_ERROR = "GET_BASIC_INFO_ERROR";
export const GET_ROASTER_RIGHT_VIEW = "GET_ROASTER_RIGHT_VIEW";
export const GET_ROASTER_RIGHT_VIEW_SUCCESS = "GET_ROASTER_RIGHT_VIEW_SUCCESS";
export const GET_ROASTER_RIGHT_VIEW_ERROR = "GET_ROASTER_RIGHT_VIEW_ERROR";
export const GET_PROVISION = "GET_PROVISION";
export const GET_PROVISION_SUCCESS = "GET_PROVISION_SUCCESS";
export const GET_PROVISION_ERROR = "GET_PROVISION_ERROR";
export const BASIC_INFO_EDIT_ACTION = "BASIC_INFO_EDIT_ACTION";
export const BASIC_INFO_EDIT_SUCCESS = "BASIC_INFO_EDIT_SUCCESS";
export const BASIC_INFO_EDIT_ERROR = "BASIC_INFO_EDIT_ERROR";
export const ATTRIBUTE_EDIT_ACTION = "ATTRIBUTE_EDIT_ACTION";
export const ATTRIBUTE_EDIT_SUCCESS = "ATTRIBUTE_EDIT_SUCCESS";
export const ATTRIBUTE_EDIT_ERROR = " ATTRIBUTE_EDIT_ERROR";
export const IDENTIFIER_EDIT_ACTION = "IDENTIFIER_EDIT_ACTION";
export const IDENTIFIER_EDIT_SUCCESS = "IDENTIFIER_EDIT_SUCCESS";
export const IDENTIFIER_EDIT_ERROR = " IDENTIFIER_EDIT_ERROR";
export const EMPLOYEMENT_EDIT_ACTION = "EMPLOYEMENT_EDIT_ACTION";
export const EMPLOYEMENT_EDIT_SUCCESS = "EMPLOYEMENT_EDIT_SUCCESS";
export const EMPLOYEMENT_EDIT_ERROR = "EMPLOYEMENT_EDIT_ERROR";
export const PROVISION_ADD_ACTION = "PROVISION_ADD_ACTION";
export const PROVISION_ADD_SUCCESS = "PROVISION_ADD_SUCCESS";
export const PROVISION_ADD_ERROR = "PROVISION_ADD_ERROR";
export const PROVISION_EDIT_ACTION = "PROVISION_EDIT_ACTION";
export const PROVISION_EDIT_SUCCESS = "PROVISION_EDIT_SUCCESS";
export const PROVISION_EDIT_ERROR = "PROVISION_EDIT_ERROR";
export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_ERROR = "DEACTIVATE_USER_ERROR";

//timekeeper
export const GET_CLOCK_PUNCH_CORRECTION = "GET_CLOCK_PUNCH_CORRECTION";
export const GET_CLOCK_PUNCH_CORRECTION_SUCCESS =
  "GET_CLOCK_PUNCH_CORRECTION_SUCCESS";
export const GET_CLOCK_PUNCH_CORRECTION_ERROR =
  "GET_CLOCK_PUNCH_CORRECTION_ERROR";
export const CLOCK_PUNCH_CORRECTION = "CLOCK_PUNCH_CORRECTION";
export const CLOCK_PUNCH_CORRECTION_SUCCESS = "CLOCK_PUNCH_CORRECTION_SUCCESS";
export const CLOCK_PUNCH_CORRECTION_ERROR = "CLOCK_PUNCH_CORRECTION_ERROR";
export const NEW_CLOCK_PUNCH_CORRECTION = "NEW_CLOCK_PUNCH_CORRECTION";

export const GET_PAID_SICK_TIME_REQUEST = "GET_PAID_SICK_TIME_REQUEST";
export const GET_PAID_SICK_TIME_REQUEST_SUCCESS =
  "GET_PAID_SICK_TIME_REQUEST_SUCCESS";
export const GET_PAID_SICK_TIME_REQUEST_ERROR =
  "GET_PAID_SICK_TIME_REQUEST_ERROR";
export const PAID_SICK_TIME_REQUEST = "PAID_SICK_TIME_REQUEST";
export const PAID_SICK_TIME_REQUEST_SUCCESS = "PAID_SICK_TIME_REQUEST_SUCCESS";
export const PAID_SICK_TIME_REQUEST_ERROR = "PAID_SICK_TIME_REQUEST_ERROR";
export const NEW_PAID_SICK_TIME_REQUEST = "NEW_PAID_SICK_TIME_REQUEST";

export const GET_VACATION_REQUEST = "GET_VACATION_REQUEST";
export const GET_VACATION_REQUEST_SUCCESS = "GET_VACATION_REQUEST_SUCCESS";
export const GET_VACATION_REQUEST_ERROR = "GET_VACATION_REQUEST_ERROR";
export const VACATION_REQUEST = "VACATION_REQUEST";
export const VACATION_REQUEST_SUCCESS = "VACATION_REQUEST_SUCCESS";
export const VACATION_REQUEST_ERROR = "VACATION_REQUEST_ERROR";
export const NEW_VACATION_REQUEST = "NEW_VACATION_REQUEST";

//topbar
export const GET_SEARCH = "GET_SEARCH";
export const GET_SEARCH_SUCCESS = "GET_SEARCH_SUCCESS";
export const GET_SEARCH_ERROR = "GET_SEARCH_ERROR";

//audit log
export const GET_AUDIT_LOG = "GET_AUDIT_LOG";
export const GET_AUDIT_LOG_SUCCESS = "GET_AUDIT_LOG_SUCCESS";
export const GET_AUDIT_LOG_ERROR = "GET_AUDIT_LOG_ERROR";

// Training
export const ADD_STUDENT_TRAINER = "ADD_STUDENT_TRAINER";
export const ADD_STUDENT_TRAINER_SUCCESS = "ADD_STUDENT_TRAINER_SUCCESS";
export const ADD_STUDENT_TRAINER_ERROR = "ADD_STUDENT_TRAINER_ERROR";
export const DELETE_SESSION_ACTION = "DELETE_SESSION_ACTION";
export const DELETE_SESSION_SUCCESS = "DELETE_SESSION_SUCCESS";
export const DELETE_SESSION_ERROR = "DELETE_SESSION_ERROR";
export const GET_NEW_SESSION_ACTION = "GET_NEW_SESSION_ACTION";
export const GET_NEW_SESSION_SUCCESS = "GET_NEW_SESSION_SUCCESS";
export const GET_NEW_SESSION_ERROR = "GET_NEW_SESSION_ERROR";
export const ADD_NEW_SESSION = "ADD_NEW_SESSION";
export const ADD_NEW_SESSION_SUCCESS = "ADD_NEW_SESSION_SUCCESS";
export const ADD_NEW_SESSION_ERROR = "ADD_NEW_SESSION_ERROR";

export const GET_TOP_COURSE = "GET_TOP_COURSE";
export const GET_TOP_COURSE_SUCCESS = "GET_TOP_COURSE_SUCCESS";
export const GET_TOP_COURSE_ERROR = "GET_TOP_COURSE_ERROR";
export const GET_STATISTIC_TAB_COUNT = "GET_STATISTIC_TAB_COUNT";
export const GET_STATISTIC_TAB_COUNT_SUCCESS =
  "GET_STATISTIC_TAB_COUNT_SUCCESS";
export const GET_STATISTIC_TAB_COUNT_ERROR = "GET_STATISTIC_TAB_COUNT_ERROR";
export const DELETE_STUDENT_TRAINER = "DELETE_STUDENT_TRAINER";
export const DELETE_STUDENT_TRAINER_SUCCESS = "DELETE_STUDENT_TRAINER_SUCCESS";
export const DELETE_STUDENT_TRAINER_ERROR = "DELETE_STUDENT_TRAINER_ERROR";

export const GET_SESSION_BY_ID_ACTION = "GET_SESSION_BY_ID_ACTION";
export const GET_SESSION_BY_ID_SUCCESS = "GET_SESSION_BY_ID_SUCCESS";
export const GET_SESSION_BY_ID_ERROR = "GET_SESSION_BY_ID_ERROR";
export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
export const ADD_LOCATION_ERROR = "ADD_LOCATION_ERROR";

//Attendance
export const GET_PERSON_LIST = "GET_PERSON_LIST";
export const GET_PERSON_LIST_SUCCESS = "GET_PERSON_LIST_SUCCESS";
export const GET_PERSON_LIST_RESET = "GET_PERSON_LIST_RESET";
export const GET_PERSON_LIST_ERROR = "GET_PERSON_LIST_ERROR";

export const GET_PERSON_BY_ID = "GET_PERSON_BY_ID";
export const GET_PERSON_BY_ID_SUCCESS = "GET_PERSON_BY_ID_SUCCESS";
export const GET_PERSON_BY_ID_ERROR = "GET_PERSON_BY_ID_ERROR";

export const GET_OCCURRENCE_DROPDOWN_LIST = "GET_OCCURRENCE_DROPDOWN_LIST";
export const GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS =
  "GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS";
export const GET_OCCURRENCE_DROPDOWN_LIST_ERROR =
  "GET_OCCURRENCE_DROPDOWN_LIST_ERROR";
export const GET_EXCUSE_DROPDOWN_ACTION = "GET_EXCUSE_DROPDOWN_ACTION";
export const GET_EXCUSE_DROPDOWN_SUCCESS = "GET_EXCUSE_DROPDOWN_SUCCESS";
export const GET_EXCUSE_DROPDOWN_ERROR = "GET_EXCUSE_DROPDOWN_ERROR";

export const ADD_OCCURRENCE = "ADD_OCCURRENCE";
export const ADD_OCCURRENCE_SUCCESS = "ADD_OCCURRENCE_SUCCESS";
export const ADD_OCCURRENCE_ERROR = "ADD_OCCURRENCE_ERROR";

export const GET_OCCURRENCE = "GET_OCCURRENCE";
export const GET_OCCURRENCE_SUCCESS = "GET_OCCURRENCE_SUCCESS";
export const GET_OCCURRENCE_ERROR = "GET_OCCURRENCE_ERROR";

export const GET_MANAGER_BY_ID = "GET_MANAGER_BY_ID";
export const GET_MANAGER_BY_ID_SUCCESS = "GET_MANAGER_BY_ID_SUCCESS";
export const GET_MANAGER_BY_ID_ERROR = "GET_MANAGER_BY_ID_ERROR";

export const GET_ATTENDANCE_SUMMARY = "GET_ATTENDANCE_SUMMARY";
export const GET_ATTENDANCE_SUMMARY_SUCCESS = "GET_ATTENDANCE_SUMMARY_SUCCESS";
export const GET_ATTENDANCE_SUMMARY_ERROR = "GET_ATTENDANCE_SUMMARY_ERROR";

export const EDIT_OCCURRENCE = "EDIT_OCCURRENCE";
export const EDIT_OCCURRENCE_SUCCESS = "EDIT_OCCURRENCE_SUCCESS";
export const EDIT_OCCURRENCE_ERROR = "EDIT_OCCURRENCE_ERROR";

export const MARK_EXCUSED = "MARK_EXCUSED";
export const MARK_EXCUSED_SUCCESS = "MARK_EXCUSED_SUCCESS";
export const MARK_EXCUSED_ERROR = "MARK_EXCUSED_ERROR";

export const MARK_RESOLVED = "MARK_RESOLVED";
export const MARK_RESOLVED_SUCCESS = "MARK_RESOLVED_SUCCESS";
export const MARK_RESOLVED_ERROR = "MARK_RESOLVED_ERROR";

export const DELETE_OCCURRENCE = "DELETE_OCCURRENCE";
export const DELETE_OCCURRENCE_SUCCESS = "DELETE_OCCURRENCE_SUCCESS";
export const DELETE_OCCURRENCE_ERROR = "DELETE_OCCURRENCE_ERROR";

export const DELETE_REASON_DROPDOWN = "DELETE_REASON_DROPDOWN";
export const DELETE_REASON_DROPDOWN_SUCCESS = "DELETE_REASON_DROPDOWN_SUCCESS";
export const DELETE_REASON_DROPDOWN_ERROR = "DELETE_REASON_DROPDOWN_ERROR";

export const UNDO_RESOLUTION = "UNDO_RESOLUTION";
export const UNDO_RESOLUTION_SUCCESS = "UNDO_RESOLUTION_SUCCESS";
export const UNDO_RESOLUTION_ERROR = "UNDO_RESOLUTION_ERROR";

export const GET_PENDING_OCCURRENCE = "GET_PENDING_OCCURRENCE";
export const GET_PENDING_OCCURRENCE_SUCCESS = "GET_PENDING_OCCURRENCE_SUCCESS";
export const GET_PENDING_OCCURRENCE_ERROR = "GET_PENDING_OCCURRENCE_ERROR";

export const UPDATE_PENDING_OCCURRENCE = "UPDATE_PENDING_OCCURRENCE";
export const UPDATE_PENDING_OCCURRENCE_SUCCESS = "UPDATE_OCCURRENCE_SUCCESS";
export const UPDATE_PENDING_OCCURRENCE_ERROR =
  "UPDATE_PENDING_OCCURRENCE_ERROR";

export const ASSIGN_OCCURRENCE = "ASSIGN_OCCURRENCE";
export const ASSIGN_OCCURRENCE_SUCCESS = "ASSIGN_OCCURRENCE_SUCCESS";
export const ASSIGN_OCCURRENCE_ERROR = "ASSIGN_OCCURRENCE_ERROR";

// export const IGNORE_OCCURRENCE = "IGNORE_OCCURRENCE";
// export const IGNORE_OCCURRENCE_SUCCESS = "IGNORE_OCCURRENCE_SUCCESS";
// export const IGNORE_OCCURRENCE_ERROR = "IGNORE_OCCURRENCE_ERROR";

export const GET_COURSE_DROPDOWN = "GET_COURSE_DROPDOWN";
export const GET_COURSE_DROPDOWN_SUCCESS = "GET_COURSE_DROPDOWN_SUCCESS";
export const GET_COURSE_DROPDOWN_ERROR = "GET_COURSE_DROPDOWN_ERROR";

export const GET_ATTENDANCE_PERMISSION = "GET_ATTENDANCE_PERMISSION";
export const GET_ATTENDANCE_PERMISSION_SUCCESS =
  "GET_ATTENDANCE_PERMISSION_SUCCESS";
export const GET_ATTENDANCE_PERMISSION_ERROR =
  "GET_ATTENDANCE_PERMISSION_ERROR";

// Provision expert
export const GET_PROVISION_EXPERT = "GET_PROVISION_EXPERT";
export const GET_PROVISION_EXPERT_SUCCESS = "GET_PROVISION_EXPERT_SUCCESS";
export const GET_PROVISION_EXPERT_ERROR = "GET_PROVISION_EXPERT_ERROR";

//User Creation
export const USER_CREATION_ACTION = "GET_USER_CREATION_ACTION";
export const USER_CREATION_SUCCESS = "GET_USER_CREATION_SUCCESS";
export const USER_CREATION_ERROR = "GET_USER_CREATION_ERROR";

export const GET_USER_STATUS_DROPDOWN_LIST = "GET_USER_STATUS_DROPDOWN_LIST";
export const GET_USER_STATUS_DROPDOWN_LIST_SUCCESS =
  "GET_USER_STATUS_DROPDOWN_LIST_SUCCESS";
export const GET_USER_STATUS_DROPDOWN_LIST_ERROR =
  "GET_USER_STATUS_DROPDOWN_LIST_ERROR";

export const GET_AD_USER_DROPDOWN_LIST = "GET_AD_USER_DROPDOWN_LIST";
export const GET_AD_USER_DROPDOWN_LIST_SUCCESS =
  "GET_AD_USER_DROPDOWN_LIST_SUCCESS";
export const GET_AD_USER_DROPDOWN_LIST_ERROR =
  "GET_AD_USER_DROPDOWN_LIST_ERROR";

export const GET_USER_TYPE_DROPDOWN_LIST = "GET_USER_TYPE_DROPDOWN_LIST";
export const GET_USER_TYPE_DROPDOWN_LIST_SUCCESS =
  "GET_USER_TYPE_DROPDOWN_LIST_SUCCESS";
export const GET_USER_TYPE_DROPDOWN_LIST_ERROR =
  "GET_USER_TYPE_DROPDOWN_LIST_ERROR";

export const GET_MIRROR_PERF_MGNT_DROPDOWN_LIST =
  "GET_MIRROR_PERF_MGNT_DROPDOWN_LIST";
export const GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS =
  "GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS";
export const GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR =
  "GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR";

export const GET_HOME_PAGE_DROPDOWN_LIST = "GET_HOME_PAGE_DROPDOWN_LIST";
export const GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS =
  "GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS";
export const GET_HOME_PAGE_DROPDOWN_LIST_ERROR =
  "GET_HOME_PAGE_DROPDOWN_LIST_ERROR";

//User Permission
export const USERPERMISSION_SEARCH_DROPDOWN_LIST =
  "USERPERMISSION_SEARCH_DROPDOWN_LIST";
export const USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS =
  "USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS";
export const USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR =
  "USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR";

//workLocation in etedForm
export const GET_ETEDFORM = "GET_ETEDFORM";
export const GET_ETEDFORM_SUCCESS = "GET_ETEDFORM_SUCCESS";
export const GET_ETEDFORM_ERROR = "GET_ETEDFORM_ERROR";
// import Bulk User
export const IMPORT_BULK_USER_REQUEST = "IMPORT_BULK_USER_REQUEST";
export const IMPORT_BULK_USER_SUCCESS = "IMPORT_BULK_USER_SUCCESS";
export const IMPORT_BULK_USER_ERROR = "IMPORT_BULK_USER_ERROR";
export const MOVE_STAGING_BULK_USER_REQUEST = "MOVE_STAGING_BULK_USER_REQUEST";
export const MOVE_STAGING_BULK_USER_SUCCESS = "MOVE_STAGING_BULK_USER_SUCCESS";
export const MOVE_STAGING_BULK_USER_ERROR = "MOVE_STAGING_BULK_USER_ERROR";
export const GET_ATTENDANCE_EXPORT = "GET_ATTENDANCE_EXPORT";
export const GET_ATTENDANCE_EXPORT_SUCCESS = "GET_ATTENDANCE_EXPORT_SUCCESS";
export const GET_ATTENDANCE_EXPORT_ERROR = "GET_ATTENDANCE_EXPORT_ERROR";

// Administration

export const GET_ORGANIZATION_DETAILS = "GET_ORGANIZATION_DETAILS";
export const GET_ORGANIZATION_DETAILS_SUCCESS =
  "GET_ORGANIZATION_DETAILS_SUCCESS";
export const GET_ORGANIZATION_DETAILS_ERROR = "GET_ORGANIZATION_DETAILS_ERROR";
export const GET_ORG_BASED_ROSTER_DATA = "GET_ORG_BASED_ROSTER_DATA";
export const GET_ORG_BASED_ROSTER_DATA_SUCCESS =
  "GET_ORG_BASED_ROSTER_DATA_SUCCESS";
export const GET_ORG_BASED_ROSTER_DATA_ERROR =
  "GET_ORG_BASED_ROSTER_DATA_ERROR";
export const TOGGLE_ROSTER = "TOGGLE_ROSTER";
export const TOGGLE_ROSTER_SUCCESS = "TOGGLE_ROSTER_SUCCESS";
export const TOGGLE_ROSTER_ERROR = "TOGGLE_ROSTER_ERROR";
export const ADD_NEW_ROSTER = "ADD_NEW_ROSTER";
export const ADD_NEW_ROSTER_SUCCESS = "ADD_NEW_ROSTER_SUCCESS";
export const ADD_NEW_ROSTER_ERROR = "ADD_NEW_ROSTER_ERROR";
export const ADD_NEW_ORGANIZATION = "ADD_NEW_ORGANIZATION";
export const ADD_NEW_ORGANIZATION_SUCCESS = "ADD_NEW_ORGANIZATION_SUCCESS";
export const ADD_NEW_ORGANIZATION_ERROR = "ADD_NEW_ORGANIZATION_ERROR";
export const GET_TITLE_ROLE = "GET_TITLE_ROLE";
export const GET_TITLE_ROLE_SUCCESS = "GET_TITLE_ROLE_SUCCESS";
export const GET_TITLE_ROLE_ERROR = "GET_TITLE_ROLE_ERROR";
export const GET_TITLE_ROLE_OPTION = "GET_TITLE_ROLE_OPTION";
export const GET_TITLE_ROLE_OPTION_SUCCESS = "GET_TITLE_ROLE_OPTION_SUCCESS";
export const GET_TITLE_ROLE_OPTION_ERROR = "GET_TITLE_ROLE_OPTION_ERROR";
export const MAP_NEW_TITLE_ROLE = "MAP_NEW_TITLE_ROLE";
export const MAP_NEW_TITLE_ROLE_SUCCESS = "MAP_NEW_TITLE_ROLE_SUCCESS";
export const MAP_NEW_TITLE_ROLE_ERROR = "MAP_NEW_TITLE_ROLE_ERROR";
export const EXPORT_ROSTER = "EXPORT_ROSTER";
export const EXPORT_ROSTER_SUCCESS = "EXPORT_ROSTER_SUCCESS";
export const EXPORT_ROSTER_RESET = "EXPORT_ROSTER_RESET";
export const EXPORT_ROSTER_ERROR = "EXPORT_ROSTER_ERROR";
export const GET_MANAGE_ROLES = "GET_MANAGE_ROLES";
export const GET_MANAGE_ROLES_SUCCESS = "GET_MANAGE_ROLES_SUCCESS";
export const GET_MANAGE_ROLES_ERROR = "GET_MANAGE_ROLES_ERROR";

export * from "./auth/actions";
export * from "./addnewuser/action";
export * from "./timekeeper/actions";
export * from "./editprofile/actions";
export * from "./topbar/actions";
export * from "./auditlog/actions";
export * from "./training/actions";
export * from "./attendance/actions";
export * from "./provision/actions";
export * from "./userCreation/action";
export * from "./userPermission/action";
export * from "./admin/action";
