import {
  USER_CREATION_ACTION,
  USER_CREATION_SUCCESS,
  USER_CREATION_ERROR,
  GET_USER_STATUS_DROPDOWN_LIST,
  GET_USER_STATUS_DROPDOWN_LIST_SUCCESS,
  GET_USER_STATUS_DROPDOWN_LIST_ERROR,
  GET_AD_USER_DROPDOWN_LIST,
  GET_AD_USER_DROPDOWN_LIST_SUCCESS,
  GET_AD_USER_DROPDOWN_LIST_ERROR,
  GET_USER_TYPE_DROPDOWN_LIST,
  GET_USER_TYPE_DROPDOWN_LIST_SUCCESS,
  GET_USER_TYPE_DROPDOWN_LIST_ERROR,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR,
  GET_HOME_PAGE_DROPDOWN_LIST,
  GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS,
  GET_HOME_PAGE_DROPDOWN_LIST_ERROR,
} from "../actions";

const INIT_STATE = {
  userCreationDetails: [],
  loading: false,
  error: "",

  userStatusDropdownDetails: [],
  userStatusDropdownLoading: false,
  userStatusDropdownError: "",

  adUserDropdownDetails: [],
  adUserDropdownLoading: false,
  adUserDropdownError: "",

  userTypeDropdownDetails: [],
  userTypeDropdownLoading: false,
  userTypeDropdownError: "",

  mirrorPerfMgntDropdownDetails: [],
  mirrorPerfMgntDropdownLoading: false,
  mirrorPerfMgntDropdownError: '',

  homePageDropdownDetails: [],
  homePageDropdownLoading: false,
  homePageDropdownError: ''
};

const createNewUserReducer = (state = INIT_STATE, action) => {
  switch(action.type){
    case USER_CREATION_ACTION:
      return {...state, loading: true, error: ''};
    case USER_CREATION_SUCCESS:
      return {...state, userCreationDetails: action.payload, loading: false, error: ''}
    case USER_CREATION_ERROR:
      return {...state,loading: false ,error: action.payload.message} 
    
    case GET_USER_STATUS_DROPDOWN_LIST:{
      return {...state, userStatusDropdownLoading: true, userStatusDropdownError: ''}
    }
    case GET_USER_STATUS_DROPDOWN_LIST_SUCCESS:
      return {...state, userStatusDropdownDetails: action.payload, userStatusDropdownLoading: false, userStatusDropdownError: ''}
    case GET_USER_STATUS_DROPDOWN_LIST_ERROR:
      return {...state, userStatusDropdownLoading: false, userStatusDropdownError: action.payload.message}

    case GET_AD_USER_DROPDOWN_LIST:
      return {...state, adUserDropdownLoading: true, adUserDropdownError: ''}
    case GET_AD_USER_DROPDOWN_LIST_SUCCESS:
      return {...state, adUserDropdownDetails: action.payload, adUserDropdownLoading: false, adUserDropdownError: ''}
    case GET_AD_USER_DROPDOWN_LIST_ERROR:
      return {...state, adUserDropdownLoading: false, adUserDropdownError: action.payload.message}

    case GET_USER_TYPE_DROPDOWN_LIST:
      return {...state, userTypeDropdownLoading: true, userTypeDropdownError: ''}
    case GET_USER_TYPE_DROPDOWN_LIST_SUCCESS:
      return {...state, userTypeDropdownDetails: action.payload, userTypeDropdownLoading: false, userTypeDropdownError: ''}
    case GET_USER_TYPE_DROPDOWN_LIST_ERROR:
      return {...state, userTypeDropdownLoading: false, userTypeDropdownError: action.payload.message}
    
    case GET_MIRROR_PERF_MGNT_DROPDOWN_LIST:
      return {...state, mirrorPerfMgntDropdownLoading: true, mirrorPerfMgntDropdownError: ''}
    case GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS:
      return {...state, mirrorPerfMgntDropdownDetails: action.payload, mirrorPerfMgntDropdownLoading: false, mirrorPerfMgntDropdownError: ''}
    case GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR:
      return {...state, mirrorPerfMgntDropdownLoading: false, mirrorPerfMgntDropdownError: action.payload.message}
    
    case GET_HOME_PAGE_DROPDOWN_LIST:
      return {...state, homePageDropdownLoading: true, homePageDropdownError: ''}
    case GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS:
      return {...state, homePageDropdownDetails: action.payload, homePageDropdownLoading: false, homePageDropdownError: ''}
    case GET_HOME_PAGE_DROPDOWN_LIST_ERROR:
      return {...state, homePageDropdownLoading: false, homePageDropdownError: action.payload.message}
    
    default: return { ...state };
  }
}

export default createNewUserReducer;
