// import { BASIC_INFO_EDIT_ACTION, BASIC_INFO_EDIT_SUCCESS, BASIC_INFO_EDIT_ERROR } from "../actions";
import {
    GET_BASIC_INFO_ACTION,
    GET_BASIC_INFO_SUCCESS,
    GET_BASIC_INFO_ERROR,
    GET_ROASTER_RIGHT_VIEW,
    GET_ROASTER_RIGHT_VIEW_SUCCESS,
    GET_ROASTER_RIGHT_VIEW_ERROR,
    GET_PROVISION,
    GET_PROVISION_SUCCESS,
    GET_PROVISION_ERROR,
    ATTRIBUTE_EDIT_ACTION, ATTRIBUTE_EDIT_ERROR, ATTRIBUTE_EDIT_SUCCESS, BASIC_INFO_EDIT_ACTION, BASIC_INFO_EDIT_ERROR, BASIC_INFO_EDIT_SUCCESS,
    EMPLOYEMENT_EDIT_ACTION, EMPLOYEMENT_EDIT_ERROR, EMPLOYEMENT_EDIT_SUCCESS, IDENTIFIER_EDIT_ACTION, IDENTIFIER_EDIT_ERROR, IDENTIFIER_EDIT_SUCCESS,
    PROVISION_ADD_ACTION, PROVISION_ADD_ERROR, PROVISION_ADD_SUCCESS,
    PROVISION_EDIT_ACTION, PROVISION_EDIT_ERROR, PROVISION_EDIT_SUCCESS,
    DEACTIVATE_USER,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_ERROR,
    PROFILE_PICTURE_LOADER,
    GET_ETEDFORM,
    GET_ETEDFORM_SUCCESS,
    GET_ETEDFORM_ERROR
} from "../actions";

const INIT_STATE = {
    editProfileDetails: [],
    editAttributeDetails: [],
    employementEditDetails: [],
    identifierEditDetails: [],
    provisionEditDetails: [],
    provisionAddDetails: [],

    basicInfoDetails: [],
    basicInfoLoading: false,
    basicInfoError: "",

    roasterRightView: [],
    roasterRightViewLoader: false,
    rosterRightViewError: "",

    provisionDetails: [],
    provisionDetailsLoading: false,
    provisionDetailsError: "",

    eTedFormWorkLocationDetails: [],

    deactivateError: ""

};

const editProfileReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROFILE_PICTURE_LOADER:
            return { ...state, basicInfoLoading: true };
        case GET_BASIC_INFO_ACTION:
            return { ...state, basicInfoLoading: true };
        case GET_BASIC_INFO_SUCCESS:
            return { ...state, basicInfoDetails: action.payload, basicInfoError: '', basicInfoLoading: false };
        case GET_BASIC_INFO_ERROR:
            return { ...state, basicInfoError: action.payload.message, basicInfoLoading: false };

        case GET_ROASTER_RIGHT_VIEW:
            return { ...state, roasterRightViewLoader: true };
        case GET_ROASTER_RIGHT_VIEW_SUCCESS:
            return { ...state, roasterRightView: action.payload, roasterRightViewLoader: false, rosterRightViewError: '', };
        case GET_ROASTER_RIGHT_VIEW_ERROR:
            return { ...state, rosterRightViewError: action.payload.message, roasterRightViewLoader: false };

        case GET_PROVISION:
            return { ...state, provisionDetailsLoading: true };
        case GET_PROVISION_SUCCESS:
            return { ...state, provisionDetails: action.payload, provisionDetailsError: '', provisionDetailsLoading: false };
        case GET_PROVISION_ERROR:
            return { ...state, provisionDetailsError: action.payload.message, provisionDetailsLoading: false };


        case BASIC_INFO_EDIT_ACTION:
            return { ...state };
        case BASIC_INFO_EDIT_SUCCESS:
            return { ...state, editProfileDetails: action.payload, error: '', };
        case BASIC_INFO_EDIT_ERROR:
            return { ...state, error: action.payload.message };

        case ATTRIBUTE_EDIT_ACTION:
            return { ...state };
        case ATTRIBUTE_EDIT_SUCCESS:
            return { ...state, editAttributeDetails: action.payload, error: '', };
        case ATTRIBUTE_EDIT_ERROR:
            return { ...state, error: action.payload.message };
        case IDENTIFIER_EDIT_ACTION:
            return { ...state };
        case IDENTIFIER_EDIT_SUCCESS:
            return { ...state, identifierEditDetails: action.payload, error: '', };
        case IDENTIFIER_EDIT_ERROR:
            return { ...state, error: action.payload.message };
        case EMPLOYEMENT_EDIT_ACTION:
            return { ...state };
        case EMPLOYEMENT_EDIT_SUCCESS:
            return { ...state, employementEditDetails: action.payload, error: '', };
        case EMPLOYEMENT_EDIT_ERROR:
            return { ...state, error: action.payload.message };

        case PROVISION_ADD_ACTION:
            return { ...state };
        case PROVISION_ADD_SUCCESS:
            return { ...state, provisionAddDetails: action.payload, error: '', };
        case PROVISION_ADD_ERROR:
            return { ...state, error: action.payload.message };

        case PROVISION_EDIT_ACTION:
            return { ...state };
        case PROVISION_EDIT_SUCCESS:
            return { ...state, provisionEditDetails: action.payload, error: '', };
        case PROVISION_EDIT_ERROR:
            return { ...state, error: action.payload.message };

        case DEACTIVATE_USER:
            return { ...state, deactivateError: '' };
        case DEACTIVATE_USER_SUCCESS:
            return { ...state, deactivateError: '' };
        case DEACTIVATE_USER_ERROR:
            return { ...state, deactivateError: action.payload.message };

        case GET_ETEDFORM:
            return { ...state };
        case GET_ETEDFORM_SUCCESS:
            return { ...state, eTedFormWorkLocationDetails: action.payload, error: '', };
        case GET_ETEDFORM_ERROR:
            return { ...state, error: action.payload.message };

        default: return { ...state };
    }
}
export default editProfileReducer