import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    LOGOUT_USER,
    SSO_USER_LOGIN,
    SET_ADMIN
} from '../actions';

const INIT_STATE = {
    auth: localStorage.getItem('user_id') || null,
    navigations: [],
    loading: false,
    error: '',
    isAdmin: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true, error: '', auth: null, navigations: [] };
        case LOGIN_USER_SUCCESS:
            // return { ...state, loading: false, user: action.payload.uid, error: '' };
            return { ...state, loading: false, auth: action.payload.userid, navigations: action.payload.navigations, error: '' }; //custom code for featherjs
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, auth: null, error: action.payload.message };
        case LOGOUT_USER:
            return { ...state, auth: null, error: '', navigations: [] };
        case SSO_USER_LOGIN:
            return { ...state, loading: false, auth: action.payload.userid, navigations: action.payload.navigations, error: '' };
        case SET_ADMIN:
            return { ...state, isAdmin: true };
        default: return { ...state };
    }
}
