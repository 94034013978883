import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorInfo: '', error: '' };
    }
    
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        this.setState({ errorInfo: errorInfo, error: error })
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className='text-center container my-5'>
                    <h1 class="alert alert-danger text-center" role="alert">Something went wrong </h1>
                    <p>{JSON.stringify(this.state.errorInfo)}</p>
                    <hr />
                    <p className='fw-bold'>{this.state.error.message}</p>
                    {/* <button className='btn btn-sm btn-dark' type='button' onClick={()=>history.goBack()}> Go Back</button> */}
                </div>
            )
        }

        return this.props.children;
    }
}
export default ErrorBoundary;