import {
  DATA_SUBMIT_ACTION,
  DATA_SUBMIT_SUCCESS,
  DATA_SUBMIT_ERROR,
  IMPORT_BULK_USER_REQUEST,
  IMPORT_BULK_USER_SUCCESS,
  IMPORT_BULK_USER_ERROR,
  MOVE_STAGING_BULK_USER_REQUEST,
  MOVE_STAGING_BULK_USER_SUCCESS,
  MOVE_STAGING_BULK_USER_ERROR,
} from "../actions";

export const dataSubmitAction = (data) => ({
  type: DATA_SUBMIT_ACTION,
  payload: data,
});
export const dataSubmitSuccess = (info) => ({
  type: DATA_SUBMIT_SUCCESS,
  payload: info,
});
export const dataSubmitError = (message) => ({
  type: DATA_SUBMIT_ERROR,
  payload: { message },
});

export const importBulkUser = (data) => ({
  type: IMPORT_BULK_USER_REQUEST,
  payload: data,
});
export const importBulkUserSuccess = (info) => ({
  type: IMPORT_BULK_USER_SUCCESS,
  payload: info,
});
export const importBulkUserError = (message) => ({
  type: IMPORT_BULK_USER_ERROR,
  payload: { message },
});

export const moveStagingBulkUser = (data) => ({
  type: MOVE_STAGING_BULK_USER_REQUEST,
  payload: data,
});
export const moveStagingBulkUserSuccess = (info) => ({
  type: MOVE_STAGING_BULK_USER_SUCCESS,
  payload: info,
});
export const moveStagingBulkUserError = (message) => ({
  type: MOVE_STAGING_BULK_USER_ERROR,
  payload: { message },
});
