import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { DataEntryAPI } from '../../config/api'
import {
    GET_CLOCK_PUNCH_CORRECTION,
    GET_PAID_SICK_TIME_REQUEST,
    GET_VACATION_REQUEST,
    CLOCK_PUNCH_CORRECTION,
    PAID_SICK_TIME_REQUEST,
    VACATION_REQUEST,
} from '../actions';
import {
    getClockPunchCorrectionSuccess,
    getClockPunchCorrectionError,
    getPaidSickTimeRequestSuccess,
    getPaidSickTimeRequestError,
    getVacationRequestSuccess,
    getVacationRequestError,
    submitClockPunchCorrectionSuccess,
    submitClockPunchCorrectionError,
    submitPaidSickTimeRequestSuccess,
    submitPaidSickTimeRequestError,
    submitVacationRequestError,
    submitVacationRequestSuccess
} from './actions';
import { getApiHeader, getCompanyGUID, getLoginPersonId } from '../../helpers/utils'
import { toast } from 'react-toastify';

function* getClockPunchCorrectionCall({ payload }) {
    try {
        const data = yield call(getClockPunchCorrectionCallAsync, payload);
        yield put(getClockPunchCorrectionSuccess(data));
    } catch (error) {
        yield put(getClockPunchCorrectionError(error));
    }
}

const getClockPunchCorrectionCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetPunchinCorrection?personid=${getLoginPersonId()}&companyguid=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
function* getPaidSickTimeRequestCall({ payload }) {
    try {
        const data = yield call(getPaidSickTimeRequestCallAsync, payload);
        yield put(getPaidSickTimeRequestSuccess(data));
    } catch (error) {
        yield put(getPaidSickTimeRequestError(error));
    }
}
const getPaidSickTimeRequestCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetPaidSickTimeRequest?personid=${payload}&companyguid=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
            return err;
        });
}
function* getVacationRequestCall({ payload }) {
    try {
        const data = yield call(getVacationRequestCallAsync, payload);
        yield put(getVacationRequestSuccess(data));
    } catch (error) {
        yield put(getVacationRequestError(error));
    }
}

const getVacationRequestCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetVacationRequest?personid=${payload}&companyguid=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err);
            return err;
        });
}

function* clockPunchCorrectionCall(data) {
    try {
        const resData = yield call(clockPunchCorrectionCallAsync, data);
        if (resData?.data?.payload) {
            yield put(submitClockPunchCorrectionSuccess(resData?.data?.payload));
        } else {
            toast.error(resData?.response?.data);
            yield put(submitClockPunchCorrectionError("Something went wrong from API"));
        }
    } catch (error) {
        yield put(submitClockPunchCorrectionError(error));

    }
}

const clockPunchCorrectionCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    payload.personId = getLoginPersonId();
    return axios({
        method: 'post',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/CreateClockPunchinCorrection`,
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    })
        .catch((err) => {
            toast.error(err)
            return err;
        });
}

function* paidSickTimeRequestCall(data) {
    try {
        const resData = yield call(paidSickTimeRequestCallAsync, data);
        if (resData?.data?.payload) {
            yield put(submitPaidSickTimeRequestSuccess(resData?.data?.payload));
        } else {
            toast.error(resData?.response?.data);
            yield put(submitPaidSickTimeRequestError("Something went wrong from API"));
        }
    } catch (error) {
        yield put(submitPaidSickTimeRequestError(error));

    }
}
const paidSickTimeRequestCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID()
    payload.personId = getLoginPersonId();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/createpaidsicktimerequest`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    })
        .catch(function (err) {
            toast.error(err)
        });
}

function* vacationRequestCall(data) {
    try {
        const resData = yield call(vacationRequestCallAsync, data);
        if (resData?.data?.message) {
            toast.error(resData?.data?.message)
            yield put(submitVacationRequestError(resData?.data?.message));
        } else if (resData?.data?.payload) {
            yield put(submitVacationRequestSuccess(resData?.data?.payload));
        } else {
            toast.error(resData?.response?.data);
            yield put(submitVacationRequestError("Something went wrong from API"));
        }
    } catch (error) {
        yield put(submitVacationRequestError(error));

    }
}
const vacationRequestCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    payload.personId = getLoginPersonId();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/createvacationrequest`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    })
        .catch((err) => {
            toast.error(err?.message);
            return err;
        });
}


export function* watchGetClockPunchCorrection() {
    yield takeEvery(GET_CLOCK_PUNCH_CORRECTION, getClockPunchCorrectionCall);
}
export function* watchGetPaidSickTimeRequest() {
    yield takeEvery(GET_PAID_SICK_TIME_REQUEST, getPaidSickTimeRequestCall);
}
export function* watchGetVacationRequest() {
    yield takeEvery(GET_VACATION_REQUEST, getVacationRequestCall);
}
export function* watchClockPunchCorrection() {
    yield takeEvery(CLOCK_PUNCH_CORRECTION, clockPunchCorrectionCall);
}
export function* watchPaidSickTimeRequest() {
    yield takeEvery(PAID_SICK_TIME_REQUEST, paidSickTimeRequestCall);
}
export function* watchVacationRequest() {
    yield takeEvery(VACATION_REQUEST, vacationRequestCall);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetClockPunchCorrection),
        fork(watchGetPaidSickTimeRequest),
        fork(watchGetVacationRequest),
        fork(watchClockPunchCorrection),
        fork(watchPaidSickTimeRequest),
        fork(watchVacationRequest),
    ]);
}