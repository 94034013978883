export const deleteReasonDropdowndata = [
    {
        id: 1,
        name: 'Assigned to wrong person'
    },
    {
        id: 2,
        name: 'Associate Notification Error',
    },
    {
        id: 3,
        name: 'Duplicate created by accident',
    },
    {
        id: 4,
        name: 'Record created for testing purposes',
    },
    {
        id: 5,
        name: 'RTA Entry Error',
    },
    

]
export default deleteReasonDropdowndata

