import {
  DATA_SUBMIT_ACTION,
  DATA_SUBMIT_SUCCESS,
  DATA_SUBMIT_ERROR,
  IMPORT_BULK_USER_REQUEST,
  IMPORT_BULK_USER_SUCCESS,
  IMPORT_BULK_USER_ERROR,
  MOVE_STAGING_BULK_USER_REQUEST,
  MOVE_STAGING_BULK_USER_SUCCESS,
  MOVE_STAGING_BULK_USER_ERROR,
} from "../actions";

const INIT_STATE = {
  DataSubmitDetails: [],
  error: "",
  bulkUser: [],
  bulkUserError: "",
  bulkUserLoading: false,
  bulkUserMoving: "",
  bulkUserMovingError: "",
  bulkUserMovingLoading: false,
};

const addNewUserReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DATA_SUBMIT_ACTION:
      return { ...state };
    case DATA_SUBMIT_SUCCESS:
      return { ...state, DataSubmitDetails: action.payload, error: "" };
    case DATA_SUBMIT_ERROR:
      return { ...state, error: action.payload.message };
    case IMPORT_BULK_USER_REQUEST:
      return { ...state, bulkUserLoading: true, bulkUserError: "" };
    case IMPORT_BULK_USER_SUCCESS:
      return {
        ...state,
        bulkUser: action.payload,
        bulkUserLoading: false,
        bulkUserError: "",
      };
    case IMPORT_BULK_USER_ERROR:
      return {
        ...state,
        bulkUserError: action.payload.message,
        bulkUserLoading: false,
      };
    case MOVE_STAGING_BULK_USER_REQUEST:
      return { ...state, bulkUserMovingLoading: true, bulkUserMovingError: "" };
    case MOVE_STAGING_BULK_USER_SUCCESS:
      return {
        ...state,
        bulkUserMoving: action.payload,
        bulkUserMovingLoading: false,
        bulkUserMovingError: "",
      };
    case MOVE_STAGING_BULK_USER_ERROR:
      return {
        ...state,
        bulkUserMovingError: action.payload.message,
        bulkUserMovingLoading: false,
      };
    default:
      return { ...state };
  }
};
export default addNewUserReducer;
