import {
    GET_AUDIT_LOG,
    GET_AUDIT_LOG_SUCCESS,
    GET_AUDIT_LOG_ERROR,
} from '../actions';

const INIT_STATE = {
    auditLogList: null,
    auditLogListLoading: false,
    auditLogListError: ''
}

const auditLogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AUDIT_LOG:
            return { ...state, auditLogList: null, auditLogListLoading: true, auditLogListError: '' };
        case GET_AUDIT_LOG_SUCCESS:
            return { ...state, auditLogList: action.payload, auditLogListLoading: false, auditLogListError: '' };
        case GET_AUDIT_LOG_ERROR:
            return { ...state, auditLogListLoading: false, auditLogListError: action.payload };
        default: return { ...state };
    }
}
export default auditLogReducer