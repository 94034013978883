import {
  GET_OCCURRENCE_DROPDOWN_LIST,
  GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS,
  GET_OCCURRENCE_DROPDOWN_LIST_ERROR,
  GET_EXCUSE_DROPDOWN_ACTION,
  GET_EXCUSE_DROPDOWN_SUCCESS,
  GET_EXCUSE_DROPDOWN_ERROR,
  GET_PERSON_LIST,
  GET_PERSON_LIST_SUCCESS,
  GET_PERSON_LIST_RESET,
  GET_PERSON_LIST_ERROR,
  ADD_OCCURRENCE,
  ADD_OCCURRENCE_SUCCESS,
  ADD_OCCURRENCE_ERROR,
  GET_OCCURRENCE,
  GET_OCCURRENCE_SUCCESS,
  GET_OCCURRENCE_ERROR,
  GET_MANAGER_BY_ID,
  GET_MANAGER_BY_ID_SUCCESS,
  GET_MANAGER_BY_ID_ERROR,
  EDIT_OCCURRENCE,
  EDIT_OCCURRENCE_SUCCESS,
  EDIT_OCCURRENCE_ERROR,
  MARK_EXCUSED,
  MARK_EXCUSED_SUCCESS,
  MARK_EXCUSED_ERROR,
  DELETE_OCCURRENCE,
  DELETE_OCCURRENCE_SUCCESS,
  DELETE_OCCURRENCE_ERROR,
  DELETE_REASON_DROPDOWN_ERROR,
  DELETE_REASON_DROPDOWN_SUCCESS,
  DELETE_REASON_DROPDOWN,
  ASSIGN_OCCURRENCE,
  ASSIGN_OCCURRENCE_SUCCESS,
  ASSIGN_OCCURRENCE_ERROR,
  // IGNORE_OCCURRENCE,
  // IGNORE_OCCURRENCE_SUCCESS,
  // IGNORE_OCCURRENCE_ERROR,
  MARK_RESOLVED,
  MARK_RESOLVED_SUCCESS,
  MARK_RESOLVED_ERROR,
  UNDO_RESOLUTION,
  UNDO_RESOLUTION_SUCCESS,
  UNDO_RESOLUTION_ERROR,
  GET_PERSON_BY_ID,
  GET_PERSON_BY_ID_SUCCESS,
  GET_PERSON_BY_ID_ERROR,
  // UPDATE_PENDING_OCCURRENCE,
  // UPDATE_PENDING_OCCURRENCE_SUCCESS,
  // UPDATE_PENDING_OCCURRENCE_ERROR,
  GET_PENDING_OCCURRENCE,
  GET_PENDING_OCCURRENCE_SUCCESS,
  GET_PENDING_OCCURRENCE_ERROR,
  GET_ATTENDANCE_SUMMARY,
  GET_ATTENDANCE_SUMMARY_SUCCESS,
  GET_ATTENDANCE_SUMMARY_ERROR,
  GET_ATTENDANCE_PERMISSION,
  GET_ATTENDANCE_PERMISSION_SUCCESS,
  GET_ATTENDANCE_PERMISSION_ERROR,
  GET_ATTENDANCE_EXPORT,
  GET_ATTENDANCE_EXPORT_SUCCESS,
  GET_ATTENDANCE_EXPORT_ERROR
} from "../actions";

const INIT_STATE = {
  occurrenceDropdownListDetails: [],
  occurrenceDropdownListLoading: false,
  occurrenceDropdownListError: "",

  excuseDropdownDetails: [],
  excuseDropdownLoading: false,
  excuseDropdownError: "",

  getPersonByIdDetails: {},
  getPersonByIdLoading: false,
  getPersonByIdError: "",

  personListDetails: [],
  personListLoading: false,
  personListError: "",

  addOccurrenceDetails: [],
  addOccurrenceLoading: false,
  addOccurrenceError: "",

  getOccurrenceDetails: [],
  getOccurrenceLoading: false,
  getOccurrenceError: "",

  getAttendanceSummayDetail: [],
  getAttendanceSummayLoading: false,
  getAttendanceSummayError: "",

  getManagerDetails: {},
  getManagerLoading: false,
  getManagerError: "",

  editOccurrenceDetail: [],
  editOccurrenceLoading: false,
  editOccurrenceError: "",

  markExcusedDetail: [],
  markExcusedLoading: false,
  markExcusedError: "",

  markResolvedDetail: [],
  markResolvedLoading: false,
  markResolvedError: "",

  deleteOccurrenceDetail: [],
  deleteOccurrenceLoading: false,
  deleteOccurrenceError: "",

  deleteReasonDropdownDetail: [],
  deleteReasonDropdownLoading: false,
  deleteReasonDropdownError: "",

  undoResolutionDetail: [],
  undoResolutionLoading: false,
  undoResolutionError: "",

  getPendingOccurrenceDetail: [],
  getPendingOccurrenceLoading: false,
  getPendingOccurrenceError: "",

  // updatePendingOccurrenceDetail: [],
  // updatePendingOccurrenceLoading: false,
  // updatePendingOccurrenceError: '',

  assignOccurrenceDetail: [],
  assignOccurrenceLoading: false,
  assignOccurrenceError: "",

  // ignoreOccurrenceDetaill: [],
  // ignoreOccurrenceLoading: false,
  // ignoreOccurrenceError: '',

  attendancePermission: {},
  attendancePermissionLoading: false,
  attendancePermissionError: "",
};

const attendanceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERSON_LIST:
      return { ...state, personListLoading: true, personListError: "" };
    case GET_PERSON_LIST_SUCCESS:
      return {
        ...state,
        personListDetails: action.payload,
        personListLoading: false,
        personListError: "",
      };
    case GET_PERSON_LIST_RESET:
      return {
        ...state,
        personListDetails: [],
        personListError: "",
      };
    case GET_PERSON_LIST_ERROR:
      return {
        ...state,
        personListLoading: false,
        personListError: action.payload.message,
      };

    case GET_PERSON_BY_ID:
      return { ...state, getPersonByIdLoading: true, getPersonByIdError: "" };
    case GET_PERSON_BY_ID_SUCCESS:
      return {
        ...state,
        getPersonByIdDetails: action.payload,
        getPersonByIdLoading: false,
        getPersonByIdError: "",
      };
    case GET_PERSON_BY_ID_ERROR:
      return {
        ...state,
        getPersonByIdLoading: false,
        getPersonByIdError: action.payload.message,
      };

    case GET_OCCURRENCE_DROPDOWN_LIST:
      return {
        ...state,
        occurrenceDropdownListLoading: true,
        occurrenceDropdownListError: "",
      };
    case GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS:
      return {
        ...state,
        occurrenceDropdownListDetails: action.payload,
        occurrenceDropdownListLoading: false,
        occurrenceDropdownListError: "",
      };
    case GET_OCCURRENCE_DROPDOWN_LIST_ERROR:
      return {
        ...state,
        occurrenceDropdownListLoading: false,
        occurrenceDropdownListError: action.payload.message,
      };

    case GET_EXCUSE_DROPDOWN_ACTION:
      return { ...state, excuseDropdownLoading: true, excuseDropdownError: "" };
    case GET_EXCUSE_DROPDOWN_SUCCESS:
      return {
        ...state,
        excuseDropdownDetails: action.payload,
        excuseDropdownLoading: false,
        excuseDropdownError: "",
      };
    case GET_EXCUSE_DROPDOWN_ERROR:
      return {
        ...state,
        excuseDropdownLoading: false,
        excuseDropdownError: action.payload.message,
      };

    case ADD_OCCURRENCE:
      return { ...state, addOccurrenceLoading: true, addOccurrenceError: "" };
    case ADD_OCCURRENCE_SUCCESS:
      return {
        ...state,
        addOccurrenceDetails: action.payload,
        addOccurrenceLoading: false,
        addOccurrenceError: "",
      };
    case ADD_OCCURRENCE_ERROR:
      return {
        ...state,
        addOccurrenceLoading: false,
        addOccurrenceError: action.payload.message,
      };

    case GET_OCCURRENCE:
      return { ...state, getOccurrenceLoading: true, getOccurrenceError: "" };
    case GET_OCCURRENCE_SUCCESS:
      return {
        ...state,
        getOccurrenceDetails: action.payload,
        getOccurrenceLoading: false,
        getOccurrenceError: "",
      };
    case GET_OCCURRENCE_ERROR:
      return {
        ...state,
        getOccurrenceLoading: false,
        getOccurrenceError: action.payload.message,
      };

    case GET_ATTENDANCE_SUMMARY:
      return {
        ...state,
        getAttendanceSummayLoading: true,
        getAttendanceSummayError: "",
      };
    case GET_ATTENDANCE_SUMMARY_SUCCESS:
      return {
        ...state,
        getAttendanceSummayDetail: action.payload,
        getAttendanceSummayLoading: false,
        getAttendanceSummayError: "",
      };
    case GET_ATTENDANCE_SUMMARY_ERROR:
      return {
        ...state,
        getAttendanceSummayLoading: false,
        getAttendanceSummayError: action.payload.message,
      };

    case GET_MANAGER_BY_ID:
      return {
        ...state,
        getManagerLoading: true,
        getManagerError: "",
        getManagerDetails: {},
      };
    case GET_MANAGER_BY_ID_SUCCESS:
      return {
        ...state,
        getManagerDetails: action.payload,
        getManagerLoading: false,
        getManagerError: "",
      };
    case GET_MANAGER_BY_ID_ERROR:
      return {
        ...state,
        getManagerLoading: false,
        getManagerError: action.payload.message,
      };

    case EDIT_OCCURRENCE:
      return { ...state, editOccurrenceLoading: true, editOccurrenceError: "" };
    case EDIT_OCCURRENCE_SUCCESS:
      return {
        ...state,
        editOccurrenceDetail: action.payload,
        editOccurrenceLoading: false,
        editOccurrenceError: "",
      };
    case EDIT_OCCURRENCE_ERROR:
      return {
        ...state,
        editOccurrenceLoading: false,
        editOccurrenceError: action.payload.message,
      };

    case MARK_EXCUSED:
      return { ...state, markExcusedLoading: true, markExcusedError: "" };
    case MARK_EXCUSED_SUCCESS:
      return {
        ...state,
        markExcusedDetail: action.payload,
        markExcusedLoading: false,
        markExcusedError: "",
      };
    case MARK_EXCUSED_ERROR:
      return {
        ...state,
        markExcusedLoading: false,
        markExcusedError: action.payload.message,
      };

    case MARK_RESOLVED:
      return { ...state, markResolvedLoading: true, markResolvedError: "" };
    case MARK_RESOLVED_SUCCESS:
      return {
        ...state,
        markResolvedDetail: action.payload,
        markResolvedLoading: false,
        markResolvedError: "",
      };
    case MARK_RESOLVED_ERROR:
      return {
        ...state,
        markResolvedLoading: false,
        markResolvedError: action.payload.message,
      };

    case DELETE_OCCURRENCE:
      return {
        ...state,
        deleteOccurrenceLoading: true,
        deleteOccurrenceError: "",
      };
    case DELETE_OCCURRENCE_SUCCESS:
      return {
        ...state,
        deleteOccurrenceDetail: action.payload,
        deleteOccurrenceLoading: false,
        deleteOccurrenceError: "",
      };
    case DELETE_OCCURRENCE_ERROR:
      return {
        ...state,
        deleteOccurrenceLoading: false,
        deleteOccurrenceError: action.payload.message,
      };

    case DELETE_REASON_DROPDOWN:
      return {
        ...state,
        deleteReasonDropdownLoading: true,
        deleteReasonDropdownError: "",
      };
    case DELETE_REASON_DROPDOWN_SUCCESS:
      return {
        ...state,
        deleteReasonDropdownDetail: action.payload,
        deleteReasonDropdownLoading: false,
        deleteReasonDropdownError: "",
      };
    case DELETE_REASON_DROPDOWN_ERROR:
      return {
        ...state,
        deleteReasonDropdownLoading: false,
        deleteReasonDropdownError: action.payload.message,
      };

    case UNDO_RESOLUTION:
      return { ...state, undoResolutionLoading: true, undoResolutionError: "" };
    case UNDO_RESOLUTION_SUCCESS:
      return {
        ...state,
        undoResolutionDetail: action.payload,
        undoResolutionLoading: false,
        undoResolutionError: "",
      };
    case UNDO_RESOLUTION_ERROR:
      return {
        ...state,
        undoResolutionLoading: false,
        undoResolutionError: action.payload.message,
      };

    case GET_PENDING_OCCURRENCE:
      return {
        ...state,
        getPendingOccurrenceLoading: true,
        getPendingOccurrenceError: "",
      };
    case GET_PENDING_OCCURRENCE_SUCCESS:
      return {
        ...state,
        getPendingOccurrenceDetail: action.payload,
        getPendingOccurrenceLoading: false,
        getPendingOccurrenceError: "",
      };
    case GET_PENDING_OCCURRENCE_ERROR:
      return {
        ...state,
        getPendingOccurrenceLoading: false,
        getPendingOccurrenceError: action.payload.message,
      };

    // case UPDATE_PENDING_OCCURRENCE:
    //     return { ...state, updatePendingOccurrenceLoading: true, updatePendingOccurrenceError: '' };
    // case UPDATE_PENDING_OCCURRENCE_SUCCESS:
    //     return { ...state, updatePendingOccurrenceDetail: action.payload, updatePendingOccurrenceLoading: false, updatePendingOccurrenceError: '', };
    // case UPDATE_PENDING_OCCURRENCE_ERROR:
    //     return { ...state, updatePendingOccurrenceLoading: false, updatePendingOccurrenceError: action.payload.message };

    case ASSIGN_OCCURRENCE:
      return {
        ...state,
        assignOccurrenceLoading: true,
        assignOccurrenceError: "",
      };
    case ASSIGN_OCCURRENCE_SUCCESS:
      return {
        ...state,
        assignOccurrenceDetail: action.payload,
        assignOccurrenceLoading: false,
        assignOccurrenceError: "",
      };
    case ASSIGN_OCCURRENCE_ERROR:
      return {
        ...state,
        assignOccurrenceLoading: false,
        assignOccurrenceError: action.payload.message,
      };

    // case IGNORE_OCCURRENCE:
    //     return { ...state, ignoreOccurrenceLoading: true, ignoreOccurrenceError: '' };
    // case IGNORE_OCCURRENCE_SUCCESS:
    //     return { ...state, ignoreOccurrenceDetaill: action.payload, ignoreOccurrenceLoading: false, ignoreOccurrenceError: '', };
    // case IGNORE_OCCURRENCE_ERROR:
    //     return { ...state, ignoreOccurrenceLoading: false, ignoreOccurrenceError: action.payload.message };

    case GET_ATTENDANCE_PERMISSION:
      return {
        ...state,
        attendancePermissionLoading: true,
        attendancePermissionError: "",
      };
    case GET_ATTENDANCE_PERMISSION_SUCCESS:
      return {
        ...state,
        attendancePermission: action.payload,
        attendancePermissionLoading: false,
        attendancePermissionError: "",
      };
    case GET_ATTENDANCE_PERMISSION_ERROR:
      return {
        ...state,
        attendancePermissionLoading: false,
        attendancePermissionError: action.payload.message,
      };

    case GET_ATTENDANCE_EXPORT:
      return {
        ...state,
        attendanceExportLoading: true,
        attendanceExportError: "",
      };
    case GET_ATTENDANCE_EXPORT_SUCCESS:
      return {
        ...state,
        attendanceExport: action.payload,
        attendanceExportLoading: false,
        attendanceExportError: "",
      };
    case GET_ATTENDANCE_EXPORT_ERROR:
      return {
        ...state,
        attendanceExportLoading: false,
        attendanceExportError: action.payload.message,
      };

    default:
      return { ...state };
  }
};
export default attendanceReducer;
