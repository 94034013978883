import {
    GET_PROVISION_EXPERT,
    GET_PROVISION_EXPERT_SUCCESS,
    GET_PROVISION_EXPERT_ERROR,
} from '../actions';

const INIT_STATE = {
    provisionExpertUrl: '',
    provisionExpertLoader: false

}

const auditLogReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PROVISION_EXPERT:
            return { ...state, provisionExpertUrl: '', provisionExpertLoader: true };
        case GET_PROVISION_EXPERT_SUCCESS:
            return { ...state, provisionExpertUrl: action.payload, provisionExpertLoader: false };
        case GET_PROVISION_EXPERT_ERROR:
            return { ...state, provisionExpertUrl: '', provisionExpertLoader: false };
        default: return { ...state };
    }
}
export default auditLogReducer