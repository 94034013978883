
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { AIMWithoutCred, DataEntryAPI } from '../../config/api'
import { toast } from 'react-toastify';

import {
    LOGIN_USER,
    LOGOUT_USER,
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
} from './actions';
import { getApiHeader } from '../../helpers/utils';
import axios from 'axios';


export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}


function* loginWithEmailPassword({ payload }) {
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, payload.user);
        if (loginUser?.status === "Valid") {
            const { userid, firstname, lastName, email,
                loginId, employeenumber, useravtarimage,
                usersession, navigations, personid } = loginUser?.usersession;
            localStorage.setItem('user_id', userid);
            localStorage.setItem('user_info', JSON.stringify(loginUser?.usersession));
            localStorage.setItem('personId', JSON.stringify(personid));            
            localStorage.setItem('navigations', JSON.stringify(navigations));

            yield put(loginUserSuccess(loginUser?.usersession));
            history.push('/');
        }
        if (loginUser.status === "In Valid") {
            toast.error("Invalid User")
            yield put(loginUserError("invalid user"));
        }
    } catch (error) {
        toast.error("Invalid User")
        yield put(loginUserError("Invalid User"));
    }
}

const loginWithEmailPasswordAsync = (payload) =>
    fetch(`${AIMWithoutCred}/Account/UserAuthentication`, {
        method: 'post',
        body: JSON.stringify(payload),
        // headers: getApiHeader(),
    })
        .then(resConv => resConv.json())
        .then((res) => res)
        .catch((err) => err);



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

// const logoutAsync = async (history) => {
//     await auth.signOut().then(authUser => authUser).catch(error => error);
//     history.push('/')
// }

const logoutFeather = (history) => {
    history.push('/')
    window.open('', "_self"); 
    window.close();
    // window.location.href = '/';
}


function* logout({ payload }) {
    const { history } = payload
    try {
        localStorage.removeItem('user_id');
        localStorage.removeItem('user_info');
        localStorage.removeItem('navigations');
        localStorage.removeItem('personId');
        yield call(logoutFeather, history);
    } catch (error) {
        toast.error(error)
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
    ]);
}