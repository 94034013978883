import {
    GET_SEARCH,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_ERROR,
} from '../actions';

export const getSearch = (data) => ({
    type: GET_SEARCH,
    payload: data
});
export const getSearchSuccess = (data) => ({
    type: GET_SEARCH_SUCCESS,
    payload: data
});
export const getSearchError = (error) => ({
    type: GET_SEARCH_ERROR,
    payload: error
});

