import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  USER_CREATION_ACTION,
  GET_USER_STATUS_DROPDOWN_LIST,
  GET_AD_USER_DROPDOWN_LIST,
  GET_USER_TYPE_DROPDOWN_LIST,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST,
  GET_HOME_PAGE_DROPDOWN_LIST,
} from "../actions";


import {
    userCreationSuccess,
    userCreationError,
    getUserStatusDropdownListSuccess,
    getUserStatusDropdownListError,
    getAdUserDropdownListSuccess,
    getAdUserDropdownListError,
    getUserTypeDropdownListSuccess,
    getUserTypeDropdownListError,
    getMirrorPerfMgntDropdownListSuccess,
    getMirrorPerfMgntDropdownListError,
    getHomePageDropdownListSuccess,
    getHomePageDropdownListError
} from './action'
import deleteReasonDropdowndata from "../../data/deleteReasonDropdown";

export function* watchUserCreation() {
    yield takeEvery(USER_CREATION_ACTION, usercreationCall)
}

function* usercreationCall({ payload }){
    try{
        const data = yield call(userCreationCallAsync, payload);
        if(data === undefined) return 
        yield put(userCreationSuccess(data))
        var userList = JSON.parse(localStorage.getItem("form-values")) || [];
        userList.push(data)
        localStorage.setItem("form-values", JSON.stringify(userList))
    } catch(error){
        yield put(userCreationError(error))
    }
}

const userCreationCallAsync = (value) => {
    return value
}

export function* watchUserStatusDropdown(){
    yield takeEvery(GET_USER_STATUS_DROPDOWN_LIST, getUserStatusDropdownCall)
}

function* getUserStatusDropdownCall(){
    try {
        const data = yield call(getUserStatusDropdownCallAsync);
        yield put(getUserStatusDropdownListSuccess(data))
    } catch (error) {
        yield put(getUserStatusDropdownListError(error))
    }
}

const getUserStatusDropdownCallAsync = () => {
    return deleteReasonDropdowndata
}

export function* watchAdUserDropdown() {
  yield takeEvery(GET_AD_USER_DROPDOWN_LIST, getAdUserDropdownCall);
}

function* getAdUserDropdownCall() {
    try {
        const data = yield call(getAdUserDropdownCallAsync);
        yield put(getAdUserDropdownListSuccess(data))
    } catch (error) {
        yield put(getAdUserDropdownListError(error))
    }
}

const getAdUserDropdownCallAsync = () => {
    return deleteReasonDropdowndata
}

export function* watchUserTypeDropdown() {
  yield takeEvery(GET_USER_TYPE_DROPDOWN_LIST, getUserTypeDropdownCall);
}

function* getUserTypeDropdownCall() {
    try {
        const data = yield call(getUserTypeDropdownCallAsync);
        yield put(getUserTypeDropdownListSuccess(data))
    } catch (error) {
        yield put(getUserTypeDropdownListError(error))
    }
}

const getUserTypeDropdownCallAsync = () => {
    return deleteReasonDropdowndata
}

export function* watchMirrorPerfMgntDropdown() {
  yield takeEvery(GET_MIRROR_PERF_MGNT_DROPDOWN_LIST, getMirrorPerfMgntDropdownCall);
}

function* getMirrorPerfMgntDropdownCall() {
    try {
        const data = yield call(getMirrorPerfMgntDropdownCallAsync);
        yield put(getMirrorPerfMgntDropdownListSuccess(data))
    } catch (error) {
        yield put(getMirrorPerfMgntDropdownListError)
    }
}

const getMirrorPerfMgntDropdownCallAsync = () => {
    return deleteReasonDropdowndata
}

export function* watchHomePageDropdown() {
  yield takeEvery(
    GET_HOME_PAGE_DROPDOWN_LIST,
    getHomePageDropdownCall
  );
}

function* getHomePageDropdownCall() {
    try {
        const data = yield call(getHomePageDropdownCallAsync);
        yield put(getHomePageDropdownListSuccess(data))
    } catch (error) {
        yield put(getHomePageDropdownListError(error))
    }
}

const getHomePageDropdownCallAsync = () => {
    return deleteReasonDropdowndata
}

export default function* rootSaga(){
    yield all([
        fork(watchUserCreation),
        fork(watchUserStatusDropdown),
        fork(watchAdUserDropdown),
        fork(watchUserTypeDropdown),
        fork(watchMirrorPerfMgntDropdown),
        fork(watchHomePageDropdown)
    ])
}