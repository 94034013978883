import { all } from "redux-saga/effects";
import editProfileSagas from "./editprofile/sagas";
import addNewUserSagas from "./addnewuser/saga";
import authSagas from "./auth/saga";
import topbarSagas from "./topbar/saga";
import auditLogSagas from "./auditlog/saga";
import timekeeperSagas from "./timekeeper/sagas";
import trainingSagas from "./training/sagas";
import attendanceSagas from "./attendance/saga";
import provisionSagas from "./provision/saga";
import userCreationSagas from "./userCreation/saga";
import userPermissionSagas from "./userPermission/saga";
import administrationSagas from "./admin/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    editProfileSagas(),
    addNewUserSagas(),
    topbarSagas(),
    auditLogSagas(),
    timekeeperSagas(),
    trainingSagas(),
    attendanceSagas(),
    provisionSagas(),
    userCreationSagas(),
    userPermissionSagas(),
    administrationSagas(),
  ]);
}
