
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DataEntryAPI } from '../../config/api'
import { toast } from 'react-toastify';

import {
    GET_PROVISION_EXPERT
} from '../actions';

import {
    getProvisionExpertSuccess,
    getProvisionExpertError,
} from './actions';
import { getApiHeader, getCompanyGUID } from '../../helpers/utils';
import axios from 'axios';

export function* watchProvisionExpert() {
    yield takeEvery(GET_PROVISION_EXPERT, getProvisionExpertCall);
}


function* getProvisionExpertCall({ payload }) {
    try {
        const data = yield call(getProvisionExpertCallAsync, payload);
        window.open(data)
        yield put(getProvisionExpertSuccess(data));
    } catch (error) {
        yield put(getProvisionExpertError(error));
    }
}

const getProvisionExpertCallAsync = (payload) => {
    const companyguid = getCompanyGUID();
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}//ExportRosterProvision?companyguid=${companyguid}&fromDate=${payload.fromDate}&endDate=${payload.endDate}`,
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}

export default function* rootSaga() {
    yield all([
        fork(watchProvisionExpert),
    ]);
}