import {
    USERPERMISSION_SEARCH_DROPDOWN_LIST,
    USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS,
    USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR
} from "../actions"

export const userPermissionSearchDropdownAction = (data) => ({
  type: USERPERMISSION_SEARCH_DROPDOWN_LIST,
  payload: data,
});
export const userPermissionSearchDropdownSuccess = (data) => ({
  type: USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS,
  payload: data,
});
export const userPermissionSearchDropdownError = (error) => ({
  type: USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR,
  payload: error,
});

