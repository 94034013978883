import {
    ADD_STUDENT_TRAINER,
    ADD_STUDENT_TRAINER_SUCCESS,
    ADD_STUDENT_TRAINER_ERROR,
    ADD_NEW_SESSION,
    ADD_NEW_SESSION_ERROR,
    ADD_NEW_SESSION_SUCCESS,   
    DELETE_SESSION_ACTION,
    DELETE_SESSION_ERROR,
    DELETE_SESSION_SUCCESS,
    GET_NEW_SESSION_ACTION,
    GET_NEW_SESSION_ERROR,
    GET_NEW_SESSION_SUCCESS,
    GET_TOP_COURSE,
    GET_TOP_COURSE_SUCCESS,
    GET_TOP_COURSE_ERROR,
    GET_STATISTIC_TAB_COUNT,
    GET_STATISTIC_TAB_COUNT_SUCCESS,
    GET_STATISTIC_TAB_COUNT_ERROR,
    DELETE_STUDENT_TRAINER,
    DELETE_STUDENT_TRAINER_ERROR,
    DELETE_STUDENT_TRAINER_SUCCESS,
    GET_SESSION_BY_ID_ACTION,
    GET_SESSION_BY_ID_SUCCESS,
    GET_SESSION_BY_ID_ERROR,
    GET_COURSE_DROPDOWN,
    GET_COURSE_DROPDOWN_SUCCESS,
    GET_COURSE_DROPDOWN_ERROR,
    ADD_LOCATION,
    ADD_LOCATION_SUCCESS,
    ADD_LOCATION_ERROR,
} from "../actions";



export const getNewSessionAction = () => ({
    type: GET_NEW_SESSION_ACTION
});
export const getNewSessionSuccess = (userPersonid) => ({
    type: GET_NEW_SESSION_SUCCESS,
    payload: userPersonid
});

export const getNewSessionError = (userPersonid) => ({
    type: GET_NEW_SESSION_ERROR,
    payload: userPersonid
});

export const addNewSession = (userPersonid) => ({
    type: ADD_NEW_SESSION,
    payload: userPersonid
});
export const addNewSessionSuccess = (userPersonid) => ({
    type: ADD_NEW_SESSION_SUCCESS,
    payload: userPersonid
});

export const addNewSessionError = (userPersonid) => ({
    type: ADD_NEW_SESSION_ERROR,
    payload: userPersonid
});
export const deleteSession = (trainingSessionId, sessionId) => ({
    type: DELETE_SESSION_ACTION,
    payload: {trainingSessionId, sessionId}
});
export const deleteSessionSuccess = (userPersonid) => ({
    type: DELETE_SESSION_SUCCESS,
    payload: userPersonid
});

export const deleteSessionError = (userPersonid) => ({
    type: DELETE_SESSION_ERROR,
    payload: userPersonid
});

export const addStudentTrainerAction = (payload) => ({
    type: ADD_STUDENT_TRAINER,
    payload
});
export const addStudentTrainerSuccess = (payload) => ({
    type: ADD_STUDENT_TRAINER_SUCCESS,
    payload
});

export const addStudentTrainerError = (error) => ({
    type: ADD_STUDENT_TRAINER_ERROR,
    error
});

export const getTopCourseAction = () => ({
    type: GET_TOP_COURSE
});
export const getTopCourseSuccess = (payload) => ({
    type: GET_TOP_COURSE_SUCCESS,
    payload
});

export const getTopCourseError = (payload) => ({
    type: GET_TOP_COURSE_ERROR,
    payload
});

export const getStatisticTabCountAction = () => ({
    type: GET_STATISTIC_TAB_COUNT
});
export const getStatisticTabCountSuccess = (payload) => ({
    type: GET_STATISTIC_TAB_COUNT_SUCCESS,
    payload
});

export const getStatisticTabCountError = (payload) => ({
    type: GET_STATISTIC_TAB_COUNT_ERROR,
    payload
});
export const deleteStudentTrainer = (assignedId,sessionId) => ({
    type: DELETE_STUDENT_TRAINER,
    payload: {assignedId,sessionId}
});
export const deleteStudentTrainerSuccess = (userPersonid) => ({
    type: DELETE_STUDENT_TRAINER_SUCCESS,
    payload: userPersonid
});

export const deleteStudentTrainerError = (userPersonid) => ({
    type: DELETE_STUDENT_TRAINER_ERROR,
    payload: userPersonid
});

export const getSessionByIdAction = (sessionId) => ({
    type: GET_SESSION_BY_ID_ACTION,
    payload: sessionId
});
export const getSessionByIdSuccess = (sessionId) => ({
    type: GET_SESSION_BY_ID_SUCCESS,
    payload:sessionId
});

export const getSessionByIdError = (sessionId) => ({
    type: GET_SESSION_BY_ID_ERROR,
    payload:sessionId
});
export const getCourseDropdown = () => ({
    type: GET_COURSE_DROPDOWN
});
export const getCourseDropdownSuccess = (payload) => ({
    type: GET_COURSE_DROPDOWN_SUCCESS,
    payload
});

export const getCourseDropdownError = (payload) => ({
    type: GET_COURSE_DROPDOWN_ERROR,
    payload
});
export const addLocatoin = (payload) => ({
    type: ADD_LOCATION,
    payload
});
export const addLocatoinSuccess = (payload) => ({
    type: ADD_LOCATION_SUCCESS,
    payload
});

export const addLocatoinError = (error) => ({
    type: ADD_LOCATION_ERROR,
    error
});
