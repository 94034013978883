import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
    GET_BASIC_INFO_ACTION,
    GET_ROASTER_RIGHT_VIEW,
    GET_PROVISION,
    ATTRIBUTE_EDIT_ACTION,
    BASIC_INFO_EDIT_ACTION,
    EMPLOYEMENT_EDIT_ACTION,
    IDENTIFIER_EDIT_ACTION,
    PROVISION_ADD_ACTION,
    PROVISION_EDIT_ACTION,
    DEACTIVATE_USER,
    GET_ETEDFORM
} from '../actions';

import { DataEntryAPI } from '../../config/api'
import {
    getBasicInfoSuccess, getBasicInfoError, getRoasterRightViewSuccess, getRoasterRightViewError,
    getProvisionSuccess, getProvisionError, attributeEditError, attributeEditSuccess,
    basicInfoEditError, basicInfoEditSuccess, employementEditError, employementEditSuccess,
    identifierEditError, identifierEditSuccess,
    provisionAddError, provisionAddSuccess,
    provisionEditError, provisionEditSuccess,
    deactivateUserSuccess, deactivateUserError,
    eTedFormWorkLocationSuccess, eTedFormWorkLocationError
} from './actions';
import { getBasicInfo, getRoasterRightView, getProvision, eTedFormWorkLocation } from '../actions'
import { getApiHeader, getCompanyGUID, getLoginPersonId } from '../../helpers/utils'
import { toast } from 'react-toastify';


function* getBasicInfoCall({ payload }) {
    try {
        const data = yield call(getBasicInfoCallAsync, payload);
        yield put(getBasicInfoSuccess(data));
    } catch (error) {
        yield put(getBasicInfoError(error));
    }
}

const getBasicInfoCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetRosterDetails?LDAPID=${payload}&companyguid=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}
function* getRosterRightViewCall({ payload }) {
    try {
        const data = yield call(getRosterRightViewCallAsync, payload);
        yield put(getRoasterRightViewSuccess(data));
    } catch (error) {
        yield put(getRoasterRightViewError(error));
    }
}

const getRosterRightViewCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetRosterRightView?personid=${payload}&companyguid=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}


function* getProvisionCall({ payload }) {
    try {
        const data = yield call(getProvisionCallAsync, payload);
        yield put(getProvisionSuccess(data));
    } catch (error) {
        yield put(getProvisionError(error));
    }
}

const getProvisionCallAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/getUserRosterProvision?ROSTER_PERSONID=${payload}&COMPANY_GUID=${getCompanyGUID()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}


function* editBasicInfoCall(data) {
    const { userPersonid } = data.payload;
    try {
        const resData = yield call(editBasicInfoCallAsync, data);
        yield put(getBasicInfo(userPersonid))
        yield put(basicInfoEditSuccess(resData));
    } catch (error) {
        yield put(basicInfoEditError(error));

    }
}

const editBasicInfoCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    payload.loginPersonid = getLoginPersonId();
    return axios({
        method: 'post',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/UpdateRosterBasicInfo`,
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    })
        .catch((err) => {
            toast.error(err)
        });
}

function* attributeProfileCall(data) {
    const { userPersonid } = data.payload;
    try {
        const resData = yield call(attributeProfileCallAsync, data);
        yield put(getBasicInfo(userPersonid))
        yield put(getRoasterRightView(userPersonid))
        yield put(attributeEditSuccess(resData));
    } catch (error) {
        yield put(attributeEditError(error));

    }
}
const attributeProfileCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID()
    payload.loginPersonid = getLoginPersonId();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/UpdateRosterAttributeInfo`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    }).catch(function (err) {
        toast.error(err.message)
        return err
    });
}

function* employementEditCall(data) {
    const { Userpersonid } = data.payload;
    try {
        const resData = yield call(employementEditCallAsync, data);
        yield put(getBasicInfo(Userpersonid))
        yield put(employementEditSuccess(resData));
    } catch (error) {
        yield put(employementEditError(error));

    }
}
const employementEditCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    payload.LoginPersonid = getLoginPersonId();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/UpdateRosterEmployeementInfo`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;

    })
        .catch((err) => {
            toast.error(err)
        });
}

function* provisionAddCall(data) {
    const { rosterPersonId } = data.payload;
    try {
        const resData = yield call(provisionAddCallAsync, data);
        yield put(getProvision(rosterPersonId));
        yield put(provisionAddSuccess(resData));
    } catch (error) {
        yield put(provisionAddError(error));

    }
}

const provisionAddCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/InsertRosterProvision`,
        headers: getApiHeader(),
        data: JSON.stringify(payload),
    }).then((res) => {
        return res;

    }).catch((err) => {
        toast.error(err)
    });
}
function* provisionEditCall(data) {
    const { rosterPersonId } = data.payload;
    try {
        const resData = yield call(provisionEditCallAsync, data);
        yield put(getProvision(rosterPersonId));
        yield put(provisionEditSuccess(resData));
    } catch (error) {
        yield put(provisionEditError(error));

    }
}

const provisionEditCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/UpdateRosterProvision`,
        headers: getApiHeader(),
        data: JSON.stringify(payload),
    }).then((res) => {
        return res;

    }).catch((err) => {
        toast.error(err)
    });
}



function* identifierEditCall(data) {
    const { personId } = data.payload;
    try {
        const resData = yield call(identifierEditCallAsync, data);
        yield put(getBasicInfo(personId))
        yield put(identifierEditSuccess(resData));
    } catch (error) {
        yield put(identifierEditError(error));

    }
}

const identifierEditCallAsync = ({ payload }) => {
    payload.companyGuid = getCompanyGUID();
    payload.loginpersonId = getLoginPersonId();
    return axios({
        method: 'post',
        url: `${DataEntryAPI}/UpdateRosterIdentifierInfo`,
        headers: getApiHeader(),
        data: JSON.stringify(payload)
    }).then((res) => {
        if (res?.data?.length) {
            res?.data?.forEach(item => {
                // Update the error message before displaying in the toast
                let errorMessage = item.msg.replace(
                    "Phone Extension Already Exists With The Another User",
                    "Phone extension already exists with another user."
                );
                toast.error(errorMessage);
            });
        }
        return res;
    }).catch((err) => {
        toast.error(err);
    });
}


// const identifierEditCallAsync = ({ payload }) => {
//     payload.companyGuid = getCompanyGUID();
//     payload.loginpersonId = getLoginPersonId();
//     return axios({
//         method: 'post',
//         url: `${DataEntryAPI}/UpdateRosterIdentifierInfo`,
//         headers: getApiHeader(),
//         data: JSON.stringify(payload)
//     }).then((res) => {
//         if (res?.data?.length) {
//             res?.data?.forEach(item => toast.error(item.msg));
//         }
//         return res
//     }).catch((err) => {
//         toast.error(err)
//     });
// }

function* deactivateUserCall(data) {
    const { userpersonid } = data.payload;
    try {
        const resData = yield call(deactivateUserCallAsync, data);
        yield put(getBasicInfo(userpersonid))
        yield put(deactivateUserSuccess(resData));
    } catch (error) {
        yield put(deactivateUserError(error));

    }
}

const deactivateUserCallAsync = ({ payload }) => {
    return axios({
        method: 'post',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/insertemployeedeactivate?companyguid=${getCompanyGUID()}&effectivedate=${payload.effectivedate}&reason=${payload.reason}&reasonid=${payload.reasonid}&userpersonid=${payload.userpersonid}&employeestatus=${payload.employeestatus}`,
        data: JSON.stringify(payload)
    }).then((res) => {
        return res;
    })
        .catch((err) => {
            toast.error(err)
        });
}


function* geteTedWorkLocationDetails({ payload }) {
    try {
        const data = yield call(geteTedWorkLocationDetailsAsync, payload);
        yield put(eTedFormWorkLocationSuccess(data));
    } catch (error) {
        yield put(eTedFormWorkLocationError(error));
    }
}

const geteTedWorkLocationDetailsAsync = (payload) => {
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GeteTedEnabledWorkLocation`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}


export function* watcheditGetBasicInfo() {
    yield takeLatest(GET_BASIC_INFO_ACTION, getBasicInfoCall);
}
export function* watchRosterRightView() {
    yield takeEvery(GET_ROASTER_RIGHT_VIEW, getRosterRightViewCall);
}
export function* watchGetProvision() {
    yield takeEvery(GET_PROVISION, getProvisionCall);
}
export function* watcheditBasicInfo() {
    yield takeEvery(BASIC_INFO_EDIT_ACTION, editBasicInfoCall);
}
export function* watcheditAttribute() {
    yield takeEvery(ATTRIBUTE_EDIT_ACTION, attributeProfileCall);
}
export function* watchEmployeeEdit() {
    yield takeEvery(EMPLOYEMENT_EDIT_ACTION, employementEditCall);
}
export function* watchidentifierEdit() {
    yield takeEvery(IDENTIFIER_EDIT_ACTION, identifierEditCall);
}
export function* watchProvisionAdd() {
    yield takeEvery(PROVISION_ADD_ACTION, provisionAddCall);
}
export function* watchProvisionEdit() {
    yield takeEvery(PROVISION_EDIT_ACTION, provisionEditCall);
}
export function* watchDeactivateUser() {
    yield takeEvery(DEACTIVATE_USER, deactivateUserCall);
}
export function* watchgeteTedWorkLocationDetails() {
    yield takeEvery(GET_ETEDFORM, geteTedWorkLocationDetails);
}



export default function* rootSaga() {
    yield all([
        fork(watcheditGetBasicInfo),
        fork(watchRosterRightView),
        fork(watchGetProvision),
        fork(watcheditBasicInfo),
        fork(watcheditAttribute),
        fork(watchEmployeeEdit),
        fork(watchidentifierEdit),
        fork(watchProvisionAdd),
        fork(watchProvisionEdit),
        fork(watchDeactivateUser),
        fork(watchgeteTedWorkLocationDetails),
    ]);
}