import {
  GET_OCCURRENCE_DROPDOWN_LIST,
  GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS,
  GET_OCCURRENCE_DROPDOWN_LIST_ERROR,
  GET_EXCUSE_DROPDOWN_ACTION,
  GET_EXCUSE_DROPDOWN_SUCCESS,
  GET_EXCUSE_DROPDOWN_ERROR,
  GET_PERSON_LIST,
  GET_PERSON_LIST_ERROR,
  GET_PERSON_LIST_SUCCESS,
  GET_PERSON_LIST_RESET,
  ADD_OCCURRENCE,
  ADD_OCCURRENCE_SUCCESS,
  ADD_OCCURRENCE_ERROR,
  GET_OCCURRENCE,
  GET_OCCURRENCE_SUCCESS,
  GET_OCCURRENCE_ERROR,
  GET_MANAGER_BY_ID,
  GET_MANAGER_BY_ID_SUCCESS,
  GET_MANAGER_BY_ID_ERROR,
  MARK_EXCUSED,
  MARK_EXCUSED_SUCCESS,
  MARK_EXCUSED_ERROR,
  DELETE_OCCURRENCE,
  DELETE_OCCURRENCE_SUCCESS,
  DELETE_OCCURRENCE_ERROR,
  EDIT_OCCURRENCE,
  EDIT_OCCURRENCE_SUCCESS,
  EDIT_OCCURRENCE_ERROR,
  ASSIGN_OCCURRENCE,
  ASSIGN_OCCURRENCE_SUCCESS,
  ASSIGN_OCCURRENCE_ERROR,
  DELETE_REASON_DROPDOWN,
  DELETE_REASON_DROPDOWN_SUCCESS,
  DELETE_REASON_DROPDOWN_ERROR,
  IGNORE_OCCURRENCE,
  IGNORE_OCCURRENCE_SUCCESS,
  IGNORE_OCCURRENCE_ERROR,
  MARK_RESOLVED,
  MARK_RESOLVED_SUCCESS,
  MARK_RESOLVED_ERROR,
  UNDO_RESOLUTION,
  UNDO_RESOLUTION_SUCCESS,
  UNDO_RESOLUTION_ERROR,
  GET_PERSON_BY_ID,
  GET_PERSON_BY_ID_SUCCESS,
  GET_PERSON_BY_ID_ERROR,
  GET_PENDING_OCCURRENCE,
  GET_PENDING_OCCURRENCE_SUCCESS,
  GET_PENDING_OCCURRENCE_ERROR,
  // UPDATE_PENDING_OCCURRENCE,
  // UPDATE_PENDING_OCCURRENCE_SUCCESS,
  // UPDATE_PENDING_OCCURRENCE_ERROR,
  GET_ATTENDANCE_SUMMARY,
  GET_ATTENDANCE_SUMMARY_SUCCESS,
  GET_ATTENDANCE_SUMMARY_ERROR,
  GET_ATTENDANCE_PERMISSION,
  GET_ATTENDANCE_PERMISSION_SUCCESS,
  GET_ATTENDANCE_PERMISSION_ERROR,
  GET_ATTENDANCE_EXPORT,
  GET_ATTENDANCE_EXPORT_SUCCESS,
  GET_ATTENDANCE_EXPORT_ERROR,
} from "../actions";

export const getPersonListAction = (data) => ({
  type: GET_PERSON_LIST,
  payload: data,
});
export const getPersonListSuccess = (data) => ({
  type: GET_PERSON_LIST_SUCCESS,
  payload: data,
});
export const getPersonListReset = (payload) => ({
  type: GET_PERSON_LIST_RESET,
  payload: payload,
});
export const getPersonListError = (error) => ({
  type: GET_PERSON_LIST_ERROR,
  payload: error,
});

export const getPersonByIdAction = (id, state = false) => ({
  type: GET_PERSON_BY_ID,
  payload: { id, state },
});
export const getPersonByIdSuccess = (payload) => ({
  type: GET_PERSON_BY_ID_SUCCESS,
  payload,
});
export const getPersonByIdError = (error) => ({
  type: GET_PERSON_BY_ID_ERROR,
  payload: error,
});

export const getOccurrenceDropdownListAction = () => ({
  type: GET_OCCURRENCE_DROPDOWN_LIST,
});
export const getOccurrenceDropdownListSuccess = (data) => ({
  type: GET_OCCURRENCE_DROPDOWN_LIST_SUCCESS,
  payload: data,
});
export const getOccurrenceDropdownListError = (error) => ({
  type: GET_OCCURRENCE_DROPDOWN_LIST_ERROR,
  payload: error,
});
export const getExcuseDropdownAction = () => ({
  type: GET_EXCUSE_DROPDOWN_ACTION,
});
export const getExcuseDropdownSuccess = (data) => ({
  type: GET_EXCUSE_DROPDOWN_SUCCESS,
  payload: data,
});
export const getExcuseDropdownError = (error) => ({
  type: GET_EXCUSE_DROPDOWN_ERROR,
  payload: error,
});

export const addOccurrenceAction = (data) => ({
  type: ADD_OCCURRENCE,
  payload: data,
});
export const addOccurrenceSuccess = (data) => ({
  type: ADD_OCCURRENCE_SUCCESS,
  payload: data,
});
export const addOccurrenceError = (error) => ({
  type: ADD_OCCURRENCE_ERROR,
  payload: error,
});

export const getOccurrenceAction = (page, size, direction) => ({
  type: GET_OCCURRENCE,
  payload: { page, size, direction },
});
export const getOccurrenceSuccess = (data) => ({
  type: GET_OCCURRENCE_SUCCESS,
  payload: data,
});
export const getOccurrenceError = (error) => ({
  type: GET_OCCURRENCE_ERROR,
  payload: error,
});

export const getManagerByIdAction = (data) => ({
  type: GET_MANAGER_BY_ID,
  payload: data,
});
export const getManagerDataByIdSuccess = (payload) => ({
  type: GET_MANAGER_BY_ID_SUCCESS,
  payload,
});
export const getManagerDataByIdError = (error) => ({
  type: GET_MANAGER_BY_ID_ERROR,
  payload: error,
});

export const getAttendanceSummaryAction = (data) => ({
  type: GET_ATTENDANCE_SUMMARY,
  payload: data,
});
export const getAttendanceSummarySuccess = (data) => ({
  type: GET_ATTENDANCE_SUMMARY_SUCCESS,
  payload: data,
});
export const getAttendanceSummaryError = (error) => ({
  type: GET_ATTENDANCE_SUMMARY_ERROR,
  payload: error,
});

//Associate modals
export const editOccurrenceAction = (data) => ({
  type: EDIT_OCCURRENCE,
  payload: data,
});
export const editOccurrenceSuccess = (data) => ({
  type: EDIT_OCCURRENCE_SUCCESS,
  payload: data,
});
export const editOccurrenceError = (error) => ({
  type: EDIT_OCCURRENCE_ERROR,
  payload: error,
});

export const markExcusedAction = (payload) => ({
  type: MARK_EXCUSED,
  payload,
});
export const markExcusedSuccess = (payload) => ({
  type: MARK_EXCUSED_SUCCESS,
  payload,
});
export const markExcusedError = (error) => ({
  type: MARK_EXCUSED_ERROR,
  payload: error,
});

export const markResolvedAction = (data) => ({
  type: MARK_RESOLVED,
  payload: data,
});
export const markResolvedSuccess = (data) => ({
  type: MARK_RESOLVED_SUCCESS,
  payload: data,
});
export const markResolvedError = (error) => ({
  type: MARK_RESOLVED_ERROR,
  payload: error,
});

export const deleteOccurrenceAction = (payload) => ({
  type: DELETE_OCCURRENCE,
  payload,
});
export const deleteOccurrenceSuccess = (payload) => ({
  type: DELETE_OCCURRENCE_SUCCESS,
  payload,
});
export const deleteOccurrenceError = (error) => ({
  type: DELETE_OCCURRENCE_ERROR,
  payload: error,
});

export const deleteReasonDropdownAction = (data) => ({
  type: DELETE_REASON_DROPDOWN,
  payload: data,
});
export const deleteReasonDropdownSuccess = (data) => ({
  type: DELETE_REASON_DROPDOWN_SUCCESS,
  payload: data,
});
export const deleteReasonDropdownError = (error) => ({
  type: DELETE_REASON_DROPDOWN_ERROR,
  payload: error,
});

export const undoResolutionAction = (data) => ({
  type: UNDO_RESOLUTION,
  payload: data,
});
export const undoResolutionSuccess = (data) => ({
  type: UNDO_RESOLUTION_SUCCESS,
  payload: data,
});
export const undoResolutionError = (error) => ({
  type: UNDO_RESOLUTION_ERROR,
  payload: error,
});

//pending request modal

export const getPendingOccurrenceAction = (
  page,
  size,
  direction,
  sortFor,
  filter
) => ({
  type: GET_PENDING_OCCURRENCE,
  payload: { page, size, direction, sortFor, filter },
});
export const getPendingOccurrenceSuccess = (data) => ({
  type: GET_PENDING_OCCURRENCE_SUCCESS,
  payload: data,
});
export const getPendingOccurrenceError = (error) => ({
  type: GET_PENDING_OCCURRENCE_ERROR,
  payload: error,
});

// export const updatePendingOccurrenceAction = (data) => ({
//     type: UPDATE_PENDING_OCCURRENCE,
//     payload: data
// });
// export const updatePendingOccurrenceSuccess = (data) => ({
//     type: UPDATE_PENDING_OCCURRENCE_SUCCESS,
//     payload: data
// });
// export const updatePendingOccurrenceError = (error) => ({
//     type: UPDATE_PENDING_OCCURRENCE_ERROR,
//     payload: error
// });

export const assignOccurrenceAction = (payload) => ({
  type: ASSIGN_OCCURRENCE,
  payload,
});
export const assignOccurrenceSuccess = (payload) => ({
  type: ASSIGN_OCCURRENCE_SUCCESS,
  payload,
});
export const assignOccurrenceError = (error) => ({
  type: ASSIGN_OCCURRENCE_ERROR,
  payload: error,
});

// export const ignoreOccurrenceAction = (data) => ({
//     type: IGNORE_OCCURRENCE,
//     payload: data
// });
// export const ignoreOccurrenceSuccess = (data) => ({
//     type: IGNORE_OCCURRENCE_SUCCESS,
//     payload: data
// });
// export const ignoreOccurrenceError = (error) => ({
//     type: IGNORE_OCCURRENCE_ERROR,
//     payload: error
// });

export const getAttendancePermissionAction = () => ({
  type: GET_ATTENDANCE_PERMISSION,
});
export const getAttendancePermissionSuccess = (payload) => ({
  type: GET_ATTENDANCE_PERMISSION_SUCCESS,
  payload,
});
export const getAttendancePermissionError = (error) => ({
  type: GET_ATTENDANCE_PERMISSION_ERROR,
  payload: error,
});

export const getAttendanceExportAction = (payload) => ({
  type: GET_ATTENDANCE_EXPORT,
  payload
});
export const getAttendanceExportSuccess = (payload) => ({
  type: GET_ATTENDANCE_EXPORT_SUCCESS,
  payload
});
export const getAttendanceExportError = (error) => ({
  type: GET_ATTENDANCE_EXPORT_ERROR,
  payload: error,
});