import { combineReducers } from "redux";
import authUser from "./auth/reducer";
import addNewUserReducer from "./addnewuser/reducer";
import editProfileReducer from "./editprofile/reducer";
import timekeeperReducer from "./timekeeper/reducer";
import topbarReducer from "./topbar/reducer";
import auditLogReducer from "./auditlog/reducer";
import trainingReducer from "./training/reducer";
import attendanceReducer from "./attendance/reducer";
import provisionReducer from "./provision/reducer";
import createNewUserReducer from "./userCreation/reducer";
import userPermissionReducer from "./userPermission/reducer";
import administrationReducer from "./admin/reducer";

const reducers = combineReducers({
  authUser,
  addNewUserReducer,
  editProfileReducer,
  timekeeperReducer,
  topbarReducer,
  auditLogReducer,
  trainingReducer,
  attendanceReducer,
  provisionReducer,
  createNewUserReducer,
  userPermissionReducer,
  administrationReducer,
});

export default reducers;
