import {
    GET_BASIC_INFO_ACTION,
    GET_BASIC_INFO_SUCCESS,
    GET_BASIC_INFO_ERROR,
    GET_ROASTER_RIGHT_VIEW,
    GET_ROASTER_RIGHT_VIEW_SUCCESS,
    GET_ROASTER_RIGHT_VIEW_ERROR,
    GET_PROVISION,
    GET_PROVISION_SUCCESS,
    GET_PROVISION_ERROR,
    ATTRIBUTE_EDIT_ACTION,
    ATTRIBUTE_EDIT_ERROR,
    ATTRIBUTE_EDIT_SUCCESS,
    BASIC_INFO_EDIT_ACTION,
    BASIC_INFO_EDIT_ERROR,
    BASIC_INFO_EDIT_SUCCESS,
    DELETE_PROFILE_ACTION,
    DELETE_PROFILE_ERROR,
    DELETE_PROFILE_SUCCESS,
    EMPLOYEMENT_EDIT_ACTION,
    EMPLOYEMENT_EDIT_ERROR,
    EMPLOYEMENT_EDIT_SUCCESS,
    IDENTIFIER_EDIT_ACTION,
    IDENTIFIER_EDIT_ERROR,
    IDENTIFIER_EDIT_SUCCESS,
    PROVISION_ADD_ACTION,
    PROVISION_ADD_ERROR,
    PROVISION_ADD_SUCCESS,
    PROVISION_EDIT_ACTION,
    PROVISION_EDIT_ERROR,
    PROVISION_EDIT_SUCCESS,
    DEACTIVATE_USER,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_ERROR,
    PROFILE_PICTURE_LOADER,
    GET_ETEDFORM,
    GET_ETEDFORM_SUCCESS,
    GET_ETEDFORM_ERROR
} from "../actions";

//Basic info
export const profilePictureUploader = () => ({
    type: PROFILE_PICTURE_LOADER,
});
export const getBasicInfo = (userPersonid) => ({
    type: GET_BASIC_INFO_ACTION,
    payload: userPersonid
});
export const getBasicInfoSuccess = (info) => ({
    type: GET_BASIC_INFO_SUCCESS,
    payload: info
});
export const getBasicInfoError = (message) => ({
    type: GET_BASIC_INFO_ERROR,
    payload: { message }
});
export const getRoasterRightView = (userPersonid) => ({
    type: GET_ROASTER_RIGHT_VIEW,
    payload: userPersonid
});
export const getRoasterRightViewSuccess = (info) => ({
    type: GET_ROASTER_RIGHT_VIEW_SUCCESS,
    payload: info
});
export const getRoasterRightViewError = (message) => ({
    type: GET_ROASTER_RIGHT_VIEW_ERROR,
    payload: { message }
});

export const getProvision = (userPersonid) => ({
    type: GET_PROVISION,
    payload: userPersonid
});
export const getProvisionSuccess = (info) => ({
    type: GET_PROVISION_SUCCESS,
    payload: info
});
export const getProvisionError = (message) => ({
    type: GET_PROVISION_ERROR,
    payload: { message }
});

export const basicInfoEditAction = (data) => ({
    type: BASIC_INFO_EDIT_ACTION,
    payload: data
});
export const basicInfoEditSuccess = (info) => ({
    type: BASIC_INFO_EDIT_SUCCESS,
    payload: info
});
export const basicInfoEditError = (message) => ({
    type: BASIC_INFO_EDIT_ERROR,
    payload: { message }
});


// Attribute
export const attributeEditAction = (data) => ({
    type: ATTRIBUTE_EDIT_ACTION,
    payload: data
});
export const attributeEditSuccess = (info) => ({
    type: ATTRIBUTE_EDIT_SUCCESS,
    payload: info
});
export const attributeEditError = (message) => ({
    type: ATTRIBUTE_EDIT_ERROR,
    payload: { message }
});
export const identifierEditAction = (data) => ({
    type: IDENTIFIER_EDIT_ACTION,
    payload: data
});
export const identifierEditSuccess = (info) => ({
    type: IDENTIFIER_EDIT_SUCCESS,
    payload: info
});
export const identifierEditError = (message) => ({
    type: IDENTIFIER_EDIT_ERROR,
    payload: { message }
});

export const employementEditAction = (data) => ({
    type: EMPLOYEMENT_EDIT_ACTION,
    payload: data
});
export const employementEditSuccess = (info) => ({
    type: EMPLOYEMENT_EDIT_SUCCESS,
    payload: info
});
export const employementEditError = (message) => ({
    type: EMPLOYEMENT_EDIT_ERROR,
    payload: { message }
});

export const provisionAddAction = (data) => ({
    type: PROVISION_ADD_ACTION,
    payload: data
});
export const provisionAddSuccess = (info) => ({
    type: PROVISION_ADD_SUCCESS,
    payload: info
});
export const provisionAddError = (message) => ({
    type: PROVISION_ADD_ERROR,
    payload: { message }
});

export const provisionEditAction = (data) => ({
    type: PROVISION_EDIT_ACTION,
    payload: data
});
export const provisionEditSuccess = (info) => ({
    type: PROVISION_EDIT_SUCCESS,
    payload: info
});
export const provisionEditError = (message) => ({
    type: PROVISION_EDIT_ERROR,
    payload: { message }
});

export const deactivateUser = (data) => ({
    type: DEACTIVATE_USER,
    payload: data
});
export const deactivateUserSuccess = (info) => ({
    type: DEACTIVATE_USER_SUCCESS,
    payload: info
});
export const deactivateUserError = (message) => ({
    type: DEACTIVATE_USER_ERROR,
    payload: { message }
});
export const eTedFormWorkLocation = (data) => ({
    type: GET_ETEDFORM,
    payload: data
});
export const eTedFormWorkLocationSuccess = (info) => ({
    type: GET_ETEDFORM_SUCCESS,
    payload: info
});
export const eTedFormWorkLocationError = (message) => ({
    type: GET_ETEDFORM_ERROR,
    payload: { message }
});