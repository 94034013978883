import {
    GET_CLOCK_PUNCH_CORRECTION,
    GET_CLOCK_PUNCH_CORRECTION_SUCCESS,
    GET_CLOCK_PUNCH_CORRECTION_ERROR,
    CLOCK_PUNCH_CORRECTION,
    CLOCK_PUNCH_CORRECTION_SUCCESS,
    CLOCK_PUNCH_CORRECTION_ERROR,
    NEW_CLOCK_PUNCH_CORRECTION,
    GET_PAID_SICK_TIME_REQUEST,
    GET_PAID_SICK_TIME_REQUEST_SUCCESS,
    GET_PAID_SICK_TIME_REQUEST_ERROR,
    PAID_SICK_TIME_REQUEST,
    PAID_SICK_TIME_REQUEST_SUCCESS,
    PAID_SICK_TIME_REQUEST_ERROR,
    NEW_PAID_SICK_TIME_REQUEST,
    GET_VACATION_REQUEST,
    GET_VACATION_REQUEST_SUCCESS,
    GET_VACATION_REQUEST_ERROR,
    VACATION_REQUEST,
    VACATION_REQUEST_SUCCESS,
    VACATION_REQUEST_ERROR,
    NEW_VACATION_REQUEST,
} from '../actions';

export const getClockPunchCorrection = (userPersonid) => ({
    type: GET_CLOCK_PUNCH_CORRECTION,
    payload: userPersonid
});
export const getClockPunchCorrectionSuccess = (info) => ({
    type: GET_CLOCK_PUNCH_CORRECTION_SUCCESS,
    payload: info
});
export const getClockPunchCorrectionError = (message) => ({
    type: GET_CLOCK_PUNCH_CORRECTION_ERROR,
    payload: { message }
});

export const submitClockPunchCorrection = (data) => ({
    type: CLOCK_PUNCH_CORRECTION,
    payload: data
});
export const submitClockPunchCorrectionSuccess = (data) => ({
    type: CLOCK_PUNCH_CORRECTION_SUCCESS,
    payload: data
});
export const submitClockPunchCorrectionError = (error) => ({
    type: CLOCK_PUNCH_CORRECTION_ERROR,
    payload: error
});
export const newClockPunchCorrection = (payload) => ({
    type: NEW_CLOCK_PUNCH_CORRECTION,
    payload
});

export const getPaidSickTimeRequest = (userPersonid) => ({
    type: GET_PAID_SICK_TIME_REQUEST,
    payload: userPersonid
});
export const getPaidSickTimeRequestSuccess = (info) => ({
    type: GET_PAID_SICK_TIME_REQUEST_SUCCESS,
    payload: info
});
export const getPaidSickTimeRequestError = (message) => ({
    type: GET_PAID_SICK_TIME_REQUEST_ERROR,
    payload: { message }
});
export const submitPaidSickTimeRequest = (data) => ({
    type: PAID_SICK_TIME_REQUEST,
    payload: data
});
export const submitPaidSickTimeRequestSuccess = (data) => ({
    type: PAID_SICK_TIME_REQUEST_SUCCESS,
    payload: data
});
export const submitPaidSickTimeRequestError = (error) => ({
    type: PAID_SICK_TIME_REQUEST_ERROR,
    payload: error
});
export const newPaidSickTimeRequest = (payload) => ({
    type: NEW_PAID_SICK_TIME_REQUEST,
    payload
});


export const getVacationRequest = (userPersonid) => ({
    type: GET_VACATION_REQUEST,
    payload: userPersonid
});
export const getVacationRequestSuccess = (info) => ({
    type: GET_VACATION_REQUEST_SUCCESS,
    payload: info
});
export const getVacationRequestError = (message) => ({
    type: GET_VACATION_REQUEST_ERROR,
    payload: { message }
});
export const submitVacationRequest = (data) => ({
    type: VACATION_REQUEST,
    payload: data
});
export const submitVacationRequestSuccess = (data) => ({
    type: VACATION_REQUEST_SUCCESS,
    payload: data
});
export const submitVacationRequestError = (error) => ({
    type: VACATION_REQUEST_ERROR,
    payload: error
});
export const newVacationRequest = (payload) => ({
    type: NEW_VACATION_REQUEST,
    payload
});

