
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DataEntryAPI } from '../../config/api'
import { toast } from 'react-toastify';

import {
    GET_SEARCH
} from '../actions';

import {
    getSearchSuccess,
    getSearchError,
} from './actions';
import { getApiHeader, getCompanyGUID, getLoginPersonId } from '../../helpers/utils';
import axios from 'axios';

export function* watchGetSearchUser() {
    yield takeEvery(GET_SEARCH, getSearchUserCall);
}


function* getSearchUserCall({ payload }) {
    try {
        const data = yield call(getSearchUserCallAsync, payload);
        yield put(getSearchSuccess(data));
    } catch (error) {
        yield put(getSearchError(error));
    }
}

const getSearchUserCallAsync = (payload) => {
    const companyguid = getCompanyGUID();
    const personid = getLoginPersonId();
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}/GetSearchListAutoComplete?companyguid=${companyguid}&personid=${personid}&searchquery=${payload.searchquery}`,        
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}


export default function* rootSaga() {
    yield all([
        fork(watchGetSearchUser),
    ]);
}