import {
    GET_AUDIT_LOG,
    GET_AUDIT_LOG_SUCCESS,
    GET_AUDIT_LOG_ERROR,
} from '../actions';

export const getAuditLog = (data) => ({
    type: GET_AUDIT_LOG,
    payload: data
});
export const getAuditLogSuccess = (data) => ({
    type: GET_AUDIT_LOG_SUCCESS,
    payload: data
});
export const getAuditLogError = (error) => ({
    type: GET_AUDIT_LOG_ERROR,
    payload: error
});

