
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DataEntryAPI } from '../../config/api'
import { toast } from 'react-toastify';

import {
    GET_AUDIT_LOG
} from '../actions';

import {
    getAuditLogSuccess,
    getAuditLogError,
} from './actions';
import { getApiHeader, getCompanyGUID, getLoginPersonId } from '../../helpers/utils';
import axios from 'axios';
import moment from 'moment';

export function* watchGetAuditLog() {
    yield takeEvery(GET_AUDIT_LOG, getAuditLogCall);
}


function* getAuditLogCall({ payload }) {
    try {
        const data = yield call(getAuditLogCallAsync, payload);
        yield put(getAuditLogSuccess(data));
    } catch (error) {
        yield put(getAuditLogError(error));
    }
}

const getAuditLogCallAsync = (payload) => {
    // const companyguid = getCompanyGUID();
    // const personid = getLoginPersonId();
    const searchDate = moment(new Date()).format('YYYY-MM-DD');
    return axios({
        method: 'GET',
        headers: getApiHeader(),
        url: `${DataEntryAPI}//GetAuditlog?auditdate=${searchDate}&userdapid=${getLoginPersonId()}`,
        data: JSON.stringify(payload)
    }).then((res) => res.data)
        .catch((err) => {
            toast.error(err)
        });
}


export default function* rootSaga() {
    yield all([
        fork(watchGetAuditLog),
    ]);
}