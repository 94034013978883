  import {
    USERPERMISSION_SEARCH_DROPDOWN_LIST,
    USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS,
    USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR
} from "../actions"      
        
const INIT_STATE = {
  userPermissionSearchDropdownDetail: [],
  userPermissionSearchDropdownLoading: false,
  userPermissionSearchDropdownError: "",
};

const userPermissionReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case USERPERMISSION_SEARCH_DROPDOWN_LIST:
        return {
          ...state,
          userPermissionSearchDropdownLoading: true,
          userPermissionSearchDropdownError: "",
        };
      case USERPERMISSION_SEARCH_DROPDOWN_LIST_SUCCESS:
        return {
          ...state,
          userPermissionSearchDropdownDetail: action.payload,
          userPermissionSearchDropdownLoading: false,
          userPermissionSearchDropdownError: "",
        };
      case USERPERMISSION_SEARCH_DROPDOWN_LIST_ERROR:
        return {
          ...state,
          userPermissionSearchDropdownLoading: false,
          userPermissionSearchDropdownError: action.payload.message,
        };

        default: return {...state}
    }
}

export default userPermissionReducer;
