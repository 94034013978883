import {
  USER_CREATION_ACTION,
  USER_CREATION_SUCCESS,
  USER_CREATION_ERROR,
  GET_USER_STATUS_DROPDOWN_LIST,
  GET_USER_STATUS_DROPDOWN_LIST_SUCCESS,
  GET_USER_STATUS_DROPDOWN_LIST_ERROR,
  GET_AD_USER_DROPDOWN_LIST,
  GET_AD_USER_DROPDOWN_LIST_SUCCESS,
  GET_AD_USER_DROPDOWN_LIST_ERROR,
  GET_USER_TYPE_DROPDOWN_LIST,
  GET_USER_TYPE_DROPDOWN_LIST_SUCCESS,
  GET_USER_TYPE_DROPDOWN_LIST_ERROR,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS,
  GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR,
  GET_HOME_PAGE_DROPDOWN_LIST,
  GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS,
  GET_HOME_PAGE_DROPDOWN_LIST_ERROR,
} from "../actions";

export const userCreationAction = (data) => ({
    type: USER_CREATION_ACTION,
    payload: data
})

export const userCreationSuccess = (info) => ({
    type: USER_CREATION_SUCCESS,
    payload: info
})

export const userCreationError = (message) => ({
    type: USER_CREATION_ERROR,
    payload: {message}
})

export const getUserStatusDropdownList = () => {
    return ({
    type: GET_USER_STATUS_DROPDOWN_LIST

})}

export const getUserStatusDropdownListSuccess = (data) => ({
  type: GET_USER_STATUS_DROPDOWN_LIST_SUCCESS,
  payload: data
});

export const getUserStatusDropdownListError = (error) => ({
  type: GET_USER_STATUS_DROPDOWN_LIST_ERROR,
  payload: error
});

export const getAdUserDropdownList = () => ({
  type: GET_AD_USER_DROPDOWN_LIST,
});

export const getAdUserDropdownListSuccess = (data) => ({
  type: GET_AD_USER_DROPDOWN_LIST_SUCCESS,
  payload: data
});

export const getAdUserDropdownListError = (error) => ({
  type: GET_AD_USER_DROPDOWN_LIST_ERROR,
  payload: error
});

export const getUserTypeDropdownList = () => ({
  type: GET_USER_TYPE_DROPDOWN_LIST,
});

export const getUserTypeDropdownListSuccess = (data) => ({
  type: GET_USER_TYPE_DROPDOWN_LIST_SUCCESS,
  payload: data
});

export const getUserTypeDropdownListError = (error) => ({
  type: GET_USER_TYPE_DROPDOWN_LIST_ERROR,
  payload: error
});

export const getMirrorPerfMgntDropdownList = () => ({
  type: GET_MIRROR_PERF_MGNT_DROPDOWN_LIST,
});

export const getMirrorPerfMgntDropdownListSuccess = (data) => ({
  type: GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_SUCCESS,
  payload: data
});

export const getMirrorPerfMgntDropdownListError = (error) => ({
  type: GET_MIRROR_PERF_MGNT_DROPDOWN_LIST_ERROR,
  payload: error
});

export const getHomePageDropdownList = () => ({
  type: GET_HOME_PAGE_DROPDOWN_LIST,
});

export const getHomePageDropdownListSuccess = (data) => ({
  type: GET_HOME_PAGE_DROPDOWN_LIST_SUCCESS,
  payload: data
});

export const getHomePageDropdownListError = (error) => ({
  type: GET_HOME_PAGE_DROPDOWN_LIST_ERROR,
  payload: error
});