import { getPendingCounts } from '../../helpers/utils';
import {
    GET_CLOCK_PUNCH_CORRECTION,
    GET_CLOCK_PUNCH_CORRECTION_SUCCESS,
    GET_CLOCK_PUNCH_CORRECTION_ERROR,
    CLOCK_PUNCH_CORRECTION,
    CLOCK_PUNCH_CORRECTION_SUCCESS,
    CLOCK_PUNCH_CORRECTION_ERROR,
    NEW_CLOCK_PUNCH_CORRECTION,
    GET_PAID_SICK_TIME_REQUEST,
    GET_PAID_SICK_TIME_REQUEST_SUCCESS,
    GET_PAID_SICK_TIME_REQUEST_ERROR,
    PAID_SICK_TIME_REQUEST,
    PAID_SICK_TIME_REQUEST_SUCCESS,
    PAID_SICK_TIME_REQUEST_ERROR,
    NEW_PAID_SICK_TIME_REQUEST,
    GET_VACATION_REQUEST,
    GET_VACATION_REQUEST_SUCCESS,
    GET_VACATION_REQUEST_ERROR,
    VACATION_REQUEST,
    VACATION_REQUEST_SUCCESS,
    VACATION_REQUEST_ERROR,
    NEW_VACATION_REQUEST,
} from '../actions';

const INIT_STATE = {
    clockPunchCorrctionList: [],
    clockPunchPendingCount: 0,
    clockPunchCorrctionLoading: false,
    clockPunchCorrctionError: '',

    submitClockPunchCorrctionData: {},
    submitClockPunchCorrctionLoading: false,
    submitClockPunchCorrctionError: '',

    paidSickTimeRequestList: [],
    paidSickPendingCount: 0,
    paidSickTimeRequestLoading: false,
    paidSickTimeRequestError: '',

    submitPaidSickTimeRequestData: {},
    submitPaidSickTimeRequestLoading: false,
    submitPaidSickTimeRequestError: '',

    vacationRequestList: [],
    vacationPendingCount: 0,
    vacationRequestLoading: false,
    vacationRequestError: '',

    submitVacationRequestData: {},
    submitVacationRequestLoading: false,
    submitVacationRequestError: '',
}

const timekeeperReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLOCK_PUNCH_CORRECTION:
            return { ...state, clockPunchCorrctionLoading: true, clockPunchCorrctionError: '' };
        case GET_CLOCK_PUNCH_CORRECTION_SUCCESS:
            return {
                ...state,
                clockPunchCorrctionList: action.payload,
                clockPunchPendingCount: getPendingCounts(action.payload),
                clockPunchCorrctionLoading: false,
                clockPunchCorrctionError: ''
            };
        case GET_CLOCK_PUNCH_CORRECTION_ERROR:
            return { ...state, clockPunchCorrctionList: [], clockPunchCorrctionLoading: false, clockPunchCorrctionError: action.payload?.message };

        case CLOCK_PUNCH_CORRECTION:
            return { ...state, submitClockPunchCorrctionLoading: true, submitClockPunchCorrctionError: '' };
        case CLOCK_PUNCH_CORRECTION_SUCCESS:
            return {
                ...state,
                submitClockPunchCorrctionLoading: false,
                submitClockPunchCorrctionData: action.payload,
                submitClockPunchCorrctionError: ''
            };
        case CLOCK_PUNCH_CORRECTION_ERROR:
            return { ...state, submitClockPunchCorrctionLoading: false, submitClockPunchCorrctionError: action.payload?.message };

        case NEW_CLOCK_PUNCH_CORRECTION:
            return { ...state, submitClockPunchCorrctionLoading: false, submitClockPunchCorrctionData: {}, submitClockPunchCorrctionError: '' };

        case GET_PAID_SICK_TIME_REQUEST:
            return { ...state, paidSickTimeRequestLoading: true, paidSickTimeRequestError: '', };
        case GET_PAID_SICK_TIME_REQUEST_SUCCESS:
            return {
                ...state,
                paidSickTimeRequestList: action.payload,
                paidSickPendingCount: getPendingCounts(action.payload),
                paidSickTimeRequestLoading: false,
                paidSickTimeRequestError: '',
            };
        case GET_PAID_SICK_TIME_REQUEST_ERROR:
            return { ...state, paidSickTimeRequestList: [], paidSickTimeRequestLoading: false, paidSickTimeRequestError: action.payload?.message, };

        case PAID_SICK_TIME_REQUEST:
            return { ...state, submitPaidSickTimeRequestLoading: true, submitPaidSickTimeRequestData: {},  submitPaidSickTimeRequestError: '', };
        case PAID_SICK_TIME_REQUEST_SUCCESS:
            return { ...state, 
                submitPaidSickTimeRequestLoading: false,
                submitPaidSickTimeRequestData: action.payload,
                 submitPaidSickTimeRequestError: '', };
        case PAID_SICK_TIME_REQUEST_ERROR:
            return { ...state, submitPaidSickTimeRequestLoading: false, submitPaidSickTimeRequestError: action.payload?.message, };

        case NEW_PAID_SICK_TIME_REQUEST:
            return { ...state, submitPaidSickTimeRequestLoading: false, submitPaidSickTimeRequestData: {}, submitPaidSickTimeRequestError: '', };


        case GET_VACATION_REQUEST:
            return { ...state, vacationRequestLoading: true, vacationRequestError: '', };
        case GET_VACATION_REQUEST_SUCCESS:
            return {
                ...state,
                vacationRequestList: action.payload,
                vacationPendingCount: getPendingCounts(action.payload),
                vacationRequestLoading: false,
                vacationRequestError: '',
            };
        case GET_VACATION_REQUEST_ERROR:
            return { ...state, vacationRequestList: [], vacationRequestLoading: false, vacationRequestError: action.payload?.message, };

        case VACATION_REQUEST:
            return { ...state, submitVacationRequestLoading: true, submitVacationRequestError: '', };
        case VACATION_REQUEST_SUCCESS:
            return { ...state, 
                submitVacationRequestLoading: false,
                submitVacationRequestData: action.payload,
                 submitVacationRequestError: '', };
        case VACATION_REQUEST_ERROR:
            return { ...state, submitVacationRequestLoading: false, submitVacationRequestData: {}, submitVacationRequestError: action.payload?.message, };

        case NEW_VACATION_REQUEST:
            return { ...state, submitVacationRequestLoading: false, submitVacationRequestData: {}, submitVacationRequestError: '', };

        default: return { ...state };
    }
}
export default timekeeperReducer