import {
    GET_PROVISION_EXPERT,
    GET_PROVISION_EXPERT_SUCCESS,
    GET_PROVISION_EXPERT_ERROR,
} from '../actions';

export const getProvisionExpert = (data) => ({
    type: GET_PROVISION_EXPERT,
    payload: data
});
export const getProvisionExpertSuccess = (data) => ({
    type: GET_PROVISION_EXPERT_SUCCESS,
    payload: data
});
export const getProvisionExpertError = (error) => ({
    type: GET_PROVISION_EXPERT_ERROR,
    payload: error
});

